import React from 'react';
import {
  SchemaTypes,
  FadeInAnimation,
} from '@reservation-app/common-used-in-web';
import { useLanguageContext } from '../../contexts/language-context';
import { SectionBasicWrapper } from './wrappers/section-basic-wrapper';

export type HtmlSectionProps = {
  section: SchemaTypes['HtmlSectionDto'];
};

// video example with src on s3 (add preload='none' to make it load only on play)
//
// <div class="py-8 px-2">
//   <video width="560" height="315" controls>
//     {' '}
//     <source
//       src="https://reservation-app-global-dev.s3.eu-central-1.amazonaws.com/fishes-video.mp4"
//       type="video/mp4"
//     />
//     Your browser does not support the video tag.
//   </video>
// </div>

const HtmlSection = ({
  section: {
    dangerouslySetInnerHTML,
    headingText,
    subHeadingText,
    scrollId,
    backgroundColor,
  },
}: HtmlSectionProps) => {
  const { activeLanguage } = useLanguageContext();
  return (
    <SectionBasicWrapper
      headingText={headingText?.[activeLanguage]}
      subHeadingText={subHeadingText?.[activeLanguage]}
      id={scrollId ? scrollId[activeLanguage] : undefined}
      wrapperClassName={backgroundColor}
    >
      <FadeInAnimation isDisabled withNoWrapper>
        <div
          dangerouslySetInnerHTML={{
            __html: dangerouslySetInnerHTML[activeLanguage],
          }}
        ></div>
      </FadeInAnimation>
    </SectionBasicWrapper>
  );
};

export default HtmlSection;
