import React from 'react';
import classNames from 'classnames';

type TwTopSeparatorPropTypes = {
  bottomClassName?: string;
  topClassName?: string;
  lineOneClassName?: string;
  lineTwoClassName?: string;
  lineThreeClassName?: string;
  mainLineClassName?: string;
  className?: string;
};

const TwTopSeparator = ({
  bottomClassName = 'text-white',
  topClassName = 'text-gray-100',
  lineOneClassName = 'text-gray-200',
  lineTwoClassName = 'text-gray-200',
  lineThreeClassName = 'text-gray-200',
  mainLineClassName = 'text-primary-main',
  className,
}: TwTopSeparatorPropTypes) => {
  return (
    <>
      <svg
        className={classNames('hidden w-full 768:block', className)}
        viewBox="0 0 1440 190"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M1440 0H0V190H1440V0Z"
            fill="currentColor"
            className={bottomClassName}
          />
          <path
            d="M1440 95.4893C790 245.489 650 -54.5107 0 95.4893V0H1440V95.4893Z"
            fill="currentColor"
            className={topClassName}
          />
          <path
            d="M0 95.4898C650 -54.5102 790 245.489 1440 95.4898"
            className={lineOneClassName}
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M0 95.4895C650 15.4895 790 175.489 1440 95.4895"
            className={lineTwoClassName}
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M0 95.4894C650 -214.511 790 405.489 1440 95.4894"
            className={lineThreeClassName}
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M0 95.4892C650 -134.511 790 325.489 1440 95.4892"
            className={mainLineClassName}
            stroke="currentColor"
            strokeWidth="2"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="1440" height="190" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg
        className="768:hidden"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 700.32 190"
        preserveAspectRatio="none"
        fill="none"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g
            style={{
              clipPath: 'url(#clip-path)',
            }}
            id="Layer_1-2"
            data-name="Layer 1"
          >
            <path
              d="M700 0H0v190h700Z"
              transform="translate(.03)"
              fill="currentColor"
              className={bottomClassName}
            />
            <path
              d="M700 0H0v52.29c262.35 3.28 437.88 83.17 700.3 86.39"
              transform="translate(.03)"
              fill="currentColor"
              className={topClassName}
            />
            <path
              d="M0 52.29c262.26 3.28 437.75 83.12 700 86.4"
              transform="translate(.03)"
              stroke="currentColor"
              className={lineOneClassName}
              strokeWidth="2"
            />
            <path
              d="M0 72.45c262.26 1.75 437.74 44.33 700 46.08"
              transform="translate(.03)"
              stroke="currentColor"
              className={lineTwoClassName}
              strokeWidth="2"
            />
            <path
              d="M0 6.21C262.26 13 437.75 178 700 184.76"
              transform="translate(.03)"
              stroke="currentColor"
              className={lineThreeClassName}
              strokeWidth="2"
            />
            <path
              d="M0 29.25c262.24 5 437.73 127.45 700 132.48"
              transform="translate(.03)"
              stroke="currentColor"
              className={mainLineClassName}
              strokeWidth="2"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default TwTopSeparator;
