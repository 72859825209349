import React from 'react';
import { SchemaTypes } from '@reservation-app/common-used-in-web';
import { HeroSectionStyles } from '@reservation-app/common-used-in-web';
import WithStatistics from './with-statistics';
import Basic from './basic';
import Separator from '../separators';

export interface HeroSectionProps {
  section: SchemaTypes['HeroSectionDto'] & {
    image: {
      gatsbyImageData: any;
    };
    collageImage: {
      gatsbyImageData: any;
    };
  };
}

const HeroSection = ({ section }: HeroSectionProps) => {
  let content: React.ReactNode;

  switch (section.style) {
    case HeroSectionStyles.BASIC:
      content = <Basic section={section} />;
      break;

    case HeroSectionStyles.WITH_STATISTICS:
      content = <WithStatistics section={section} />;
      break;

    default:
      content = <Basic section={section} />;
      break;
  }

  return (
    <>
      {content}
      <Separator
        style={section.bottomSeparator.style}
        metadata={section.bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default HeroSection;
