import React from 'react';
import { SectionTypes } from '@reservation-app/common-used-in-web';
import TestimonialsSection from '../sections/testimonials/index';
import Seo from './seo';
import PartnersSection from '../sections/partners';
import AlternatingContentSection from '../sections/alternating-content';
import { usePageContext } from '../../contexts/page-context';
import ContactsSection from '../sections/contacts';
import EcosystemSection from '../sections/ecosystem';
import GallerySection from '../sections/gallery';
import HeroSection from '../sections/hero';
import HtmlSection from '../sections/html';
import PricesSection from '../sections/prices';
import RecordsSection from '../sections/records';
import ScheduleSection from '../sections/schedule';
import ServicesSection from '../sections/services';
import StatisticsSection from '../sections/statistics';
import FishingSpecificSection from '../sections/fishing-specific';

const Page = () => {
  const { sections, seo, siteUrl, pageUrl, companySeo } = usePageContext();

  return (
    <>
      <Seo
        {...seo}
        companySeo={companySeo}
        siteUrl={siteUrl}
        pageUrl={pageUrl}
      />
      {sections
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((section: any, index) => {
          const isLandingPage = pageUrl.en === '/';
          const isFirstAfterHeroSection = index === 1;

          const sectionCommonProps = {
            key: section._id,
            section: section.data,
            isFirstSectionAfterLandingPage:
              isFirstAfterHeroSection && isLandingPage,
          };

          switch (section.data.type) {
            case SectionTypes.HERO:
              return <HeroSection {...sectionCommonProps} />;
            case SectionTypes.FISHING_SPECIFIC:
              return <FishingSpecificSection {...sectionCommonProps} />;
            case SectionTypes.SCHEDULE:
              return <ScheduleSection {...sectionCommonProps} />;
            case SectionTypes.TESTIMONIALS:
              return <TestimonialsSection {...sectionCommonProps} />;
            case SectionTypes.SERVICES:
              return <ServicesSection {...sectionCommonProps} />;
            case SectionTypes.ALTERNATING_CONTENT:
              return <AlternatingContentSection {...sectionCommonProps} />;
            case SectionTypes.GALLERY:
              return <GallerySection {...sectionCommonProps} />;
            case SectionTypes.RECORDS:
              return <RecordsSection {...sectionCommonProps} />;
            case SectionTypes.ECOSYSTEM:
              return <EcosystemSection {...sectionCommonProps} />;
            case SectionTypes.CONTACTS:
              return <ContactsSection {...sectionCommonProps} />;
            case SectionTypes.STATISTICS:
              return <StatisticsSection {...sectionCommonProps} />;
            case SectionTypes.PRICES:
              return <PricesSection {...sectionCommonProps} />;
            case SectionTypes.PARTNERS:
              return <PartnersSection {...sectionCommonProps} />;
            case SectionTypes.HTML:
              return <HtmlSection {...sectionCommonProps} />;
            default:
              return null;
          }
        })}
    </>
  );
};

export default Page;
