import { SeparatorStyles } from '@reservation-app/common-used-in-web';
import React from 'react';
import HorizontalLineSeparator from './horizontal-line-separator';
import TwBottomSeparator from './tw-bottom-separator';
import TwTopSeparator from './tw-top-separator';
import FootballUpwardsSeparator from './football-upwards-separator';
import FootballDownwardsSeparator from './football-downwards-separator';
import FootballDoubleSeparator from './football-double-separator';
import DiagonalLineRightSeparator from './diagonal-line-right-separator';
import DiagonalLineLeftSeparator from './diagonal-line-left-separator';

export interface SeparatorProps {
  style: string;
  metadata: string;
  location: 'top' | 'bottom';
}

const Separator = ({ style, metadata, location }: SeparatorProps) => {
  let content: React.ReactElement;

  if (location === 'top') {
    switch (style) {
      case SeparatorStyles.TAILWIND: {
        content = <TwTopSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.DIAGONAL_LINE_RIGHT: {
        content = <DiagonalLineRightSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.DIAGONAL_LINE_LEFT: {
        content = <DiagonalLineLeftSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.HORIZONTAL_LINE: {
        content = <HorizontalLineSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.FOOTBALL_UPWARDS: {
        content = <FootballUpwardsSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.FOOTBALL_DOWNWARDS: {
        content = <FootballDownwardsSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.FOOTBALL_DOUBLE: {
        content = <FootballDoubleSeparator {...JSON.parse(metadata)} />;
        break;
      }

      default:
        content = null;

        break;
    }
  }
  if (location === 'bottom') {
    switch (style) {
      case SeparatorStyles.TAILWIND: {
        content = <TwBottomSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.DIAGONAL_LINE_RIGHT: {
        content = <DiagonalLineRightSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.DIAGONAL_LINE_LEFT: {
        content = <DiagonalLineLeftSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.HORIZONTAL_LINE: {
        content = <HorizontalLineSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.FOOTBALL_UPWARDS: {
        content = <FootballUpwardsSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.FOOTBALL_DOWNWARDS: {
        content = <FootballDownwardsSeparator {...JSON.parse(metadata)} />;
        break;
      }

      case SeparatorStyles.FOOTBALL_DOUBLE: {
        content = <FootballDoubleSeparator {...JSON.parse(metadata)} />;
        break;
      }

      default:
        content = null;

        break;
    }
  }

  return content;
};

export default Separator;
