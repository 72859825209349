import React from 'react';

export const FacebookLogo = ({ className }: { className: string }) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M30 0.234375C46.5686 0.234375 60 13.6658 60 30.2344C60 45.2082 49.0294 57.6193 34.6875 59.8699V38.9062H41.6777L43.0078 30.2344H34.6875V24.6069C34.6875 22.2344 35.8497 19.9219 39.5764 19.9219H43.3594V12.5391C43.3594 12.5391 39.9262 11.9531 36.6439 11.9531C29.7913 11.9531 25.3125 16.1063 25.3125 23.625V30.2344H17.6953V38.9062H25.3125V59.8699C10.9706 57.6193 0 45.2082 0 30.2344C0 13.6658 13.4314 0.234375 30 0.234375Z"
      fill="currentColor"
    />
  </svg>
);
