import { isLastIndex, SchemaTypes } from '@reservation-app/common-used-in-web';
import React from 'react';
import GallerySubsection from './gallery-subsection';

export interface GallerySubsectionsProps {
  sortedSubsections: SchemaTypes['GallerySectionDto']['subsections'];
  setSelectedSubsection: (
    subsection: SchemaTypes['GallerySectionDto']['subsections'][0],
  ) => void;
  setSubsectionModalStartAtIndex: (index: number) => void;
  setIsGallerySubsectionModalOpen: (value: boolean) => void;
  limitBeforeExpand: number;
}

const GallerySubsections = ({
  sortedSubsections,
  setSelectedSubsection,
  setSubsectionModalStartAtIndex,
  setIsGallerySubsectionModalOpen,
  limitBeforeExpand,
}: GallerySubsectionsProps) => {
  return (
    <>
      {sortedSubsections.map((subsection, index) => {
        const isLast = isLastIndex(index, sortedSubsections);

        return (
          <GallerySubsection
            key={index}
            subsection={subsection}
            isLast={isLast}
            limitBeforeExpand={limitBeforeExpand}
            setIsGallerySubsectionModalOpen={setIsGallerySubsectionModalOpen}
            setSelectedSubsection={setSelectedSubsection}
            setSubsectionModalStartAtIndex={setSubsectionModalStartAtIndex}
          />
        );
      })}
    </>
  );
};

export default GallerySubsections;
