import React from 'react';
import { SectionBasicWrapper } from './wrappers/section-basic-wrapper';
import { useLanguageContext } from '../../contexts/language-context';
import {
  Icon,
  IconKey,
  SchemaTypes,
  FadeInAnimation,
} from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import Separator from './separators';
import Image from '../image';

export interface EcosystemSectionProps {
  section: SchemaTypes['EcosystemSectionDto'];
}

const EcosystemSection = ({
  section: {
    items,
    centerIcon,
    centerText,
    backgroundColor,
    headingText,
    subHeadingText,
    topSeparator,
    bottomSeparator,
    scrollId,
    text,
  },
}: EcosystemSectionProps) => {
  const { activeLanguage } = useLanguageContext();

  const itemCount = items.length;

  const angle = 360 / itemCount;

  const sortedItems = [...items].sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  return (
    <>
      <Separator
        style={topSeparator.style}
        metadata={topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={headingText?.[activeLanguage]}
        subHeadingText={subHeadingText?.[activeLanguage]}
        wrapperClassName={backgroundColor}
        textWrapperClassName="mb-14 768:mb-16 1024:mb-16"
        id={scrollId ? scrollId[activeLanguage] : undefined}
      >
        <div
          className="circle-container mt-3 768:mt-8 m-[5em auto 0] border border-primary-200 relative rounded-full p-0 list-none
           [--item-size:50px] 420:[--item-size:100px] 768:[--item-size:150px]
        [--circle-size:200px] 420:[--circle-size:300px] 768:[--circle-size:350px] w-[var(--circle-size)] h-[var(--circle-size)] flex justify-center items-center"
        >
          <div className="absolute border border-primary-300 border-opacity-70 rounded-full h-[72.5%] w-[72.5%] flex justify-center items-center">
            <div className="absoulte border border-primary-300 rounded-full h-[62.5%] w-[62.5%] flex justify-center items-center">
              <div className="absoulte border border-primary-400 rounded-full h-[50%] w-[50%] flex justify-center items-center">
                <div className="flex justify-center items-center">
                  <Icon
                    icon={centerIcon as IconKey}
                    className="flex-shrink-0 w-4 h-4 420:w-6 420:h-6 mr-0.5 text-gray-900"
                  />
                  <p className="font-bold whitespace-nowrap text-xs 420:text-base text-gray-900">
                    {centerText[activeLanguage]}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {sortedItems.map((item, index) => {
            return (
              <FadeInAnimation key={index}>
                <div
                  className="flex justify-center items-center absolute top-1/2 left-1/2 my-[calc(var(--item-size)/-2)] mx-[calc(var(--item-size)/-2)]"
                  style={{
                    width: 'var(--item-size)',
                    height: 'var(--item-size)',

                    transform: `rotate(${
                      index * angle
                    }deg) translate(calc(var(--circle-size)/2)) rotate(${
                      index * angle * -1
                    }deg)`,
                  }}
                >
                  <div className="flex flex-col justify-center items-center">
                    <Image
                      // @ts-ignore - it exists but too hard to type
                      image={item.image.gatsbyImageData}
                      src={item.image.url}
                      alt=""
                      imgClassName="rounded-md"
                      className={classNames('w-20 rounded-md')}
                    />
                    <p
                      className="font-bold text-sm text-gray-900 text-center"
                      dangerouslySetInnerHTML={{
                        __html: item.fishName[activeLanguage],
                      }}
                    ></p>
                    {item.percentageValue ? (
                      <p className="font-bold text-xs text-gray-900 text-center">
                        {item.percentageValue}%
                      </p>
                    ) : null}
                  </div>
                </div>
              </FadeInAnimation>
            );
          })}
        </div>
        <FadeInAnimation>
          {text?.[activeLanguage] && (
            <div
              className="mt-20 max-w-md text-center 768:max-w-lg text-gray-900"
              dangerouslySetInnerHTML={{
                __html: text[activeLanguage],
              }}
            ></div>
          )}
        </FadeInAnimation>
      </SectionBasicWrapper>
      <Separator
        style={bottomSeparator.style}
        metadata={bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default EcosystemSection;
