import React from 'react';
import classNames from 'classnames';
import { SectionBasicWrapper } from './wrappers/section-basic-wrapper';
import Separator from './separators';
import { useLanguageContext } from '../../contexts/language-context';
import Image from '../image';
import {
  SchemaTypes,
  FadeInAnimation,
} from '@reservation-app/common-used-in-web';

export type ServicesSectionProps = {
  section: SchemaTypes['ServicesSectionDto'];
  isFirstSectionAfterLandingPage: boolean;
};

const ServicesSection = ({
  section,
  isFirstSectionAfterLandingPage,
}: ServicesSectionProps) => {
  const { activeLanguage } = useLanguageContext();

  const sortedServices = [...section.services].sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  return (
    <>
      <Separator
        style={section.topSeparator.style}
        metadata={section.topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={section.headingText?.[activeLanguage]}
        subHeadingText={section.subHeadingText?.[activeLanguage]}
        wrapperClassName={classNames(section.backgroundColor, {
          'pt-6': isFirstSectionAfterLandingPage,
        })}
        id={section.scrollId ? section.scrollId[activeLanguage] : undefined}
      >
        <div className="flex justify-center flex-wrap gap-14 1280:gap-8 w-full">
          {sortedServices.map((service, index) => (
            <FadeInAnimation
              className={classNames(
                'w-full 1280:flex-[0_1_30%] 800:flex-[1_1_45%] flex-[1_0_100%] flex flex-col max-w-md',
              )}
              key={index}
            >
              <div
                className={classNames(
                  'flex flex-col items-center justify-center',
                )}
              >
                <Image
                  // @ts-ignore - it exists but too hard to type
                  image={service.image.gatsbyImageData}
                  src={service.image.url}
                  alt=""
                  imgClassName="rounded-lg"
                  className={classNames(
                    'rounded-lg mb-5 960:mb-10 w-2/3 max-w-sm 930:max-w-lg',
                  )}
                />
              </div>
              <p
                className={classNames(
                  'text-xl font-bold text-gray-800 mb-4 w-full text-center',
                )}
              >
                {service.name[activeLanguage]}
              </p>
              <p
                className={classNames(
                  'text-gray-600 text-center max-w-md 930:max-w-lg',
                )}
              >
                {service.description[activeLanguage]}
              </p>
              {/* TODO: add this optionally */}
              {/* <Button className="mb-10">Learn more</Button> */}
            </FadeInAnimation>
          ))}
        </div>
      </SectionBasicWrapper>
      <Separator
        style={section.bottomSeparator.style}
        metadata={section.bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default ServicesSection;
