import React, { useEffect, useState } from 'react';
import { useLanguageContext } from '../../../contexts/language-context';
import classNames from 'classnames';
import {
  Icon,
  Modal,
  SchemaTypes,
  Slider,
} from '@reservation-app/common-used-in-web';
import { usePageContext } from '../../../contexts/page-context';
import Image from '../../image';
import LocalizedLink from '../../localized-link';
import {
  reservationPageLanguageMapping,
  verifyAvailabilityPageLanguageMapping,
} from '../../../static-content';
import { FacebookLogo } from '../../svgs/facebook';
import { GoogleLogo } from '../../svgs/google';
import { YoutubeLogo } from '../../svgs/youtube';
import { InstagramLogo } from '../../svgs/instagram';
import { GoogleMapsLogo } from '../../svgs/google-maps';

export interface HeroSectionProps {
  section: SchemaTypes['HeroSectionDto'] & {
    image: {
      gatsbyImageData: any;
    };
    collageImage: {
      gatsbyImageData: any;
    };
  };
}

const BasicHeroSection = ({ section }: HeroSectionProps) => {
  const [isSsrDone, setIsSsrDone] = useState(false);

  const { activeLanguage, translations } = useLanguageContext();
  const { reservationConfig, availabilityCheckConfig } = usePageContext();

  const { socialMetrics } = section;

  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);

  useEffect(() => {
    setIsSsrDone(true);
  }, []);

  const areThereAnySocialMetrics =
    socialMetrics.facebookFollowers ||
    socialMetrics.facebookLikes ||
    socialMetrics.facebookLink ||
    socialMetrics.googleServiceLink ||
    socialMetrics.googleServiceRating ||
    socialMetrics.googleServiceRatingNumberOfReviewers;

  const areThereAnyFacebookMetrics =
    socialMetrics.facebookFollowers ||
    socialMetrics.facebookLikes ||
    socialMetrics.facebookLink;

  const areThereAnyGoogleMetrics =
    socialMetrics.googleServiceLink ||
    socialMetrics.googleServiceRating ||
    socialMetrics.googleServiceRatingNumberOfReviewers;

  const isFollowUsStyle = section.followUsText?.[activeLanguage];
  // [background:linear-gradient(180deg,_#FCEEC9_0%,_#E1F3FF_25.52%,_#EEF8FF_67.19%,_#FFFFFF_100%)]
  return (
    <section className="relative flex flex-col max-h-full pt-16 930:pt-16 bg-gradient-to-b from-primary-100 via-yellow-100 to-white">
      <div
        className={classNames(
          'w-full flex flex-col 930:flex-row custom-container pt-8 pb-10 930:pt-12 930:pb-12 1024:pt-14 1024:pb-14',
        )}
      >
        <div className="flex flex-col items-center mb-8 930:mb-0 930:self-center 930:items-start 930:max-w-[460px] 1280:max-w-[600px] flex-shrink-0 930:mr-8 1024:mr-12 1280:mr-14">
          {socialMetrics.googleMapsLink && (
            <a
              className="flex items-center mb-2"
              href={socialMetrics.googleMapsLink}
            >
              <GoogleMapsLogo className="h-6 mr-1" />
              <span className="text-base font-semibold text-gray-700">
                {translations.seeOnMap?.[activeLanguage]}
              </span>
            </a>
          )}
          <h1 className="mb-4 text-3xl font-bold text-center text-gray-900 930:px-0 1280:text-4xl 930:text-left">
            {section.headingText[activeLanguage]}
          </h1>
          <h2
            className={classNames(
              'w-full max-w-md text-base text-center text-gray-800 930:text-lg 1280:text-xl 930:text-left mb-6',
            )}
          >
            {section.subHeadingText[activeLanguage]}
          </h2>
          <div className="flex flex-col 930:flex-row">
            {reservationConfig.isActive && (
              <LocalizedLink
                to={`/${reservationPageLanguageMapping[activeLanguage]}`}
                className="btn-md btn-primary-filled 930:btn-lg 930:pl-5 930:pr-5 930:h-[46px] text-white hover:text-white mb-4 930:mb-0 930:mr-4"
              >
                {section.callToActionText[activeLanguage]}
              </LocalizedLink>
            )}
            {availabilityCheckConfig.isActive && (
              <LocalizedLink
                to={`/${verifyAvailabilityPageLanguageMapping[activeLanguage]}`}
                className="btn-md btn-primary-outline !text-primary-main hover:bg-primary-100 hover:text-primary-900 930:btn-lg 930:pl-5 930:pr-5 930:h-[46px]"
              >
                {section.secondaryCallToActionText[activeLanguage]}
              </LocalizedLink>
            )}
          </div>
          {isFollowUsStyle && (
            <div className="flex flex-col mt-8">
              <p className="mb-2 text-lg font-semibold text-center text-gray-700 930:text-left">
                {section.followUsText[activeLanguage]}
              </p>
              <div className="flex flex-wrap space-x-6">
                {socialMetrics.facebookLink && (
                  <a href={socialMetrics.facebookLink}>
                    <FacebookLogo className="flex-shrink-0 w-8 h-8 text-gray-700 640:w-12 640:h-12" />
                  </a>
                )}
                {socialMetrics.youtubeLink && (
                  <a href={socialMetrics.youtubeLink}>
                    <YoutubeLogo className="flex-shrink-0 w-8 h-8 text-gray-700 640:w-12 640:h-12" />
                  </a>
                )}
                {socialMetrics.instagramLink && (
                  <a href={socialMetrics.instagramLink}>
                    <InstagramLogo className="flex-shrink-0 w-8 h-8 text-gray-700 640:w-12 640:h-12" />
                  </a>
                )}
              </div>
            </div>
          )}
          {areThereAnySocialMetrics && !isFollowUsStyle && (
            <div className="flex flex-wrap items-center justify-center gap-6 pt-4 mt-4 930:mt-10 640:gap-10">
              {areThereAnyFacebookMetrics && (
                <a
                  className="flex items-center justify-center"
                  href={socialMetrics.facebookLink}
                >
                  <FacebookLogo className="flex-shrink-0 w-8 h-8 mr-3 640:mr-4 640:w-12 640:h-12 text-primary-main" />
                  <div className="flex items-center space-x-3">
                    {socialMetrics.facebookLikes && (
                      <div className="flex flex-col">
                        <div className="flex items-baseline space-x-3">
                          <p className="text-xl font-semibold text-gray-600 whitespace-nowrap">
                            {socialMetrics.facebookLikes}
                          </p>
                        </div>
                        <p className="text-gray-600 whitespace-nowrap">
                          {translations?.likes?.[activeLanguage]}
                        </p>
                      </div>
                    )}
                    {socialMetrics.facebookFollowers && (
                      <div className="flex flex-col">
                        <div className="flex items-baseline space-x-3">
                          <p className="text-xl font-semibold text-gray-600 whitespace-nowrap">
                            {socialMetrics.facebookFollowers}
                          </p>
                        </div>
                        <p className="text-gray-600 whitespace-nowrap">
                          {translations?.followers?.[activeLanguage]}
                        </p>
                      </div>
                    )}
                  </div>
                </a>
              )}
              {areThereAnyGoogleMetrics && (
                <a
                  className="flex items-center justify-center"
                  href={socialMetrics.googleServiceLink}
                >
                  <GoogleLogo className="flex-shrink-0 w-8 h-8 mr-3 640:mr-4 640:w-12 640:h-12" />
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <Icon
                        icon="star"
                        className="text-[#FBBB01] w-5 h-5 mr-1"
                      />
                      <p className="text-xl font-semibold text-gray-600 whitespace-nowrap">
                        {socialMetrics.googleServiceRating} / 5
                      </p>
                    </div>
                    <p className="text-gray-600">
                      <span className="font-semibold whitespace-nowrap">
                        {socialMetrics.googleServiceRatingNumberOfReviewers}
                      </span>{' '}
                      {translations?.reviews?.[activeLanguage]}
                    </p>
                  </div>
                </a>
              )}
            </div>
          )}
        </div>
        <div
          onClick={() => {
            if (section.images?.length > 0) {
              setIsGalleryModalOpen(true);
            }
          }}
          className={classNames(
            'self-center flex justify-center items-center 930:ml-auto max-w-lg 930:max-w-full w-full 930:w-auto',
            {
              'cursor-pointer': section.images?.length > 0,
            },
          )}
        >
          <Image
            alt="hero"
            loading="eager"
            image={section.collageImage.gatsbyImageData}
            className="rounded-l-lg"
            src={section.collageImage.url}
          />
        </div>
        {isSsrDone && (
          <Modal
            modalClassName="p-0 bg-transparent w-full"
            overlayClassName="p-0"
            isOpen={isGalleryModalOpen}
            onClose={() => {
              setIsGalleryModalOpen(false);
            }}
            restoreFocus={false}
            autoFocus={false}
            withScrollLock={false}
          >
            <Slider
              itemsToScroll={1}
              itemsToShow={1}
              mainContainerClassName="w-full 768:max-w-5xl mx-auto"
              slidesContainerClassName="768:rounded-lg"
              withArrows={true}
              arrowPosition="dots"
              dotsContainerClassName="py-0.5 px-2 bg-white rounded-md shadow-md"
              breakpoints={[
                {
                  minWidth: 768,
                  config: {
                    itemsToScroll: 1,
                    itemsToShow: 1,
                    withArrows: true,
                    arrowPosition: 'slider',
                    leftArrowClassName: 'mr-2',
                    rightArrowClassName: 'ml-2',
                  },
                },
              ]}
            >
              {section.images?.map((image, index) => (
                <Image
                  key={index}
                  // @ts-ignore
                  image={image.gatsbyImageData}
                  src={image.url}
                  alt=""
                  imgClassName="768:rounded-md w-full"
                  className="w-full shadow-md 768:rounded-md"
                />
              ))}
            </Slider>
          </Modal>
        )}
      </div>
    </section>
  );
};

export default BasicHeroSection;
