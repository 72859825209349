import { FadeInAnimation } from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import React from 'react';
import { useLanguageContext } from '../../../contexts/language-context';
import Image from '../../image';
import { BasicRecordsSectionProps } from './basic';

const TimelineRecordsSection = ({
  sortedRecords,
}: BasicRecordsSectionProps) => {
  const { translations, activeLanguage } = useLanguageContext();

  return (
    <div className="w-full mt-12 640:mt-24 max-w-2xl">
      {sortedRecords.map((record, index) => {
        const isEven = (index + 1) % 2 === 0;
        return (
          <FadeInAnimation
            className={classNames('flex justify-between mb-3', {
              'flex-row-reverse': !isEven,
            })}
            key={index}
          >
            <Image
              // @ts-ignore - it exists but too hard to type
              image={record.image.gatsbyImageData}
              src={record.image.url}
              alt=""
              imgClassName="rounded-md"
              className={classNames('rounded-md w-1/2')}
            />
            <div className="relative">
              <div className="flex justify-center items-center w-full h-full">
                <div className="h-[175%] flex justify-center items-center w-0.5 bg-gray-200 relative mx-5 640:mx-10">
                  <div className="absolute w-3 h-3 bg-white border-2 border-gray-200 rounded-full -mt-1.5"></div>
                </div>
              </div>
            </div>
            <div
              className={classNames('flex flex-col justify-center w-1/2', {
                'items-end': !isEven,
                'items-start': isEven,
              })}
            >
              <p
                className={classNames('font-semibold text-base 640:text-lg', {
                  'text-left': isEven,
                  'text-right': !isEven,
                })}
              >
                {record.recordValue[activeLanguage]}{' '}
                {record.fishName[activeLanguage]}
              </p>
              <p
                className={classNames('text-xs 640:flex', {
                  'text-left': isEven,
                  'text-right': !isEven,
                })}
              >
                <span className="640:mr-1">
                  {translations.caughtBy?.[activeLanguage]}
                </span>{' '}
                <span className="font-bold">{record.caughtBy}</span>
              </p>
            </div>
          </FadeInAnimation>
        );
      })}
    </div>
  );
};

export default TimelineRecordsSection;
