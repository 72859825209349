import React from 'react';
import { SectionBasicWrapper } from '../wrappers/section-basic-wrapper';
import { useLanguageContext } from '../../../contexts/language-context';
import { SchemaTypes } from '@reservation-app/common-used-in-web';
import Basic from './basic';
import Separator from '../separators';
import { RecordsSectionStyles } from '@reservation-app/common-used-in-web';
import TimelineRecordsSection from './timeline';
export interface RecordsSectionProps {
  section: SchemaTypes['RecordsSectionDto'];
}

const RecordsSection = ({
  section: {
    records,
    backgroundColor,
    headingText,
    subHeadingText,
    style,
    topSeparator,
    bottomSeparator,
    scrollId,
    textBelowHtml,
  },
}: RecordsSectionProps) => {
  const { activeLanguage } = useLanguageContext();

  const sortedRecords = [...records].sort(
    (a, b) => a.displayOrder - b.displayOrder,
  );

  let content: React.ReactNode;

  switch (style) {
    case RecordsSectionStyles.TIMELINE:
      content = <TimelineRecordsSection sortedRecords={sortedRecords} />;
      break;

    default:
      content = <Basic sortedRecords={sortedRecords} />;
      break;
  }

  return (
    <>
      <Separator
        style={topSeparator.style}
        metadata={topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={headingText?.[activeLanguage]}
        subHeadingText={subHeadingText?.[activeLanguage]}
        wrapperClassName={backgroundColor}
        id={scrollId ? scrollId[activeLanguage] : undefined}
      >
        {content}
        {textBelowHtml?.[activeLanguage] && (
          <div
            className="mt-10 max-w-sm text-center 768:max-w-lg text-gray-900"
            dangerouslySetInnerHTML={{
              __html: textBelowHtml[activeLanguage],
            }}
          ></div>
        )}
      </SectionBasicWrapper>
      <Separator
        style={bottomSeparator.style}
        metadata={bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default RecordsSection;
