import React from 'react';
import classNames from 'classnames';

import { SectionBasicWrapper } from './wrappers/section-basic-wrapper';
import {
  Icon,
  IconKey,
  SchemaTypes,
  FadeInAnimation,
} from '@reservation-app/common-used-in-web';
import { useLanguageContext } from '../../contexts/language-context';
import Separator from './separators';

export type StatisticsSectionProps = {
  section: SchemaTypes['StatisticsSectionDto'];
};

const StatisticsSection = ({ section }: StatisticsSectionProps) => {
  const { activeLanguage } = useLanguageContext();

  // @ts-ignore: buggy type in schematypes
  const sortedStatistics = [...(section.dryStatistics || [])].sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  // @ts-ignore: buggy type in schematypes
  const sortedDryStatistics = [...(section.dryStatistics || [])].sort(
    (a, b) => {
      return a.displayOrder - b.displayOrder;
    },
  );

  return (
    <>
      <Separator
        style={section.topSeparator.style}
        metadata={section.topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={section.headingText?.[activeLanguage]}
        subHeadingText={section.subHeadingText?.[activeLanguage]}
        wrapperClassName={classNames(section.backgroundColor)}
        id={section.scrollId ? section.scrollId[activeLanguage] : undefined}
      >
        <FadeInAnimation className="flex flex-wrap justify-center w-full gap-10 640:gap-8">
          {sortedStatistics.map((item, index) => (
            <div
              key={`${item.label[activeLanguage]}-${index}`}
              className="w-full 930:flex-[0_1_26%] 640:flex-[1_1_45%] flex-[1_0_100%] flex flex-col items-center max-w-md mb-3"
            >
              <div className="relative flex items-center">
                {item.icon && (
                  <div className="absolute top-0 bottom-0 flex items-center -left-7">
                    <Icon
                      icon={item.icon as IconKey}
                      className="w-6 h-6 mr-2 text-primary-main"
                    />
                  </div>
                )}
                <h3 className="mb-2 text-5xl font-bold text-center text-primary-main">
                  {item.number}
                  {item.secondaryLabel && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.secondaryLabel[activeLanguage],
                      }}
                    ></div>
                  )}
                </h3>
              </div>
              <p className="text-xl tracking-wide text-center text-gray-900 uppercase whitespace-nowrap">
                {item.label[activeLanguage]}
              </p>
            </div>
          ))}
          {sortedDryStatistics.map((item, index) => (
            <div
              key={`${item.label[activeLanguage]}-${index}`}
              className="w-full 930:flex-[0_1_26%] 640:flex-[1_1_45%] flex-[1_0_100%] flex flex-col items-center max-w-md mb-3"
            >
              <div className="relative flex items-center">
                {item.icon && (
                  <div className="absolute bottom-0 flex items-center -left-8 -top-3">
                    <Icon
                      icon={item.icon as IconKey}
                      className="w-6 h-6 mr-2 text-primary-main"
                    />
                  </div>
                )}
                <h3 className="mb-3 text-2xl font-bold text-center text-gray-900 whitespace-nowrap">
                  {item.title[activeLanguage] && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.title[activeLanguage],
                      }}
                    ></div>
                  )}
                </h3>
              </div>
              {item.htmlBodyText[activeLanguage] && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.htmlBodyText[activeLanguage],
                  }}
                  className="text-xl tracking-wide text-center text-gray-900 uppercase whitespace-nowrap"
                ></div>
              )}
            </div>
          ))}
        </FadeInAnimation>
      </SectionBasicWrapper>
      <Separator
        style={section.bottomSeparator.style}
        metadata={section.bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default StatisticsSection;
