import classNames from 'classnames';
import React from 'react';

type DiagonalLineRightSeparatorPropTypes = {
  bottomClassName?: string;
  topClassName?: string;
  lineClassName?: string;
  className?: string;
};

const DiagonalLineRightSeparator = ({
  bottomClassName = 'text-white',
  topClassName = 'text-gray-100',
  lineClassName = 'text-primary-main',
  className,
}: DiagonalLineRightSeparatorPropTypes) => {
  return (
    <>
      <svg
        strokeMiterlimit={10}
        strokeWidth="2px"
        fill="none"
        stroke="none"
        className={classNames('hidden 640:block', className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 187"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <polygon
              className={bottomClassName}
              fill="currentColor"
              stroke="currentColor"
              points="0.06 142 0.06 186 1440.06 186 1440.06 49 0.06 142"
            />
            <polygon
              className={topClassName}
              fill="currentColor"
              stroke="currentColor"
              points="0.06 0 0.06 142 1440.06 49 1440.06 0 0.06 0"
            />
            <line
              className={lineClassName}
              fill="currentColor"
              stroke="currentColor"
              x1="1440.06"
              y1="48"
              x2="0.06"
              y2="141"
            />
          </g>
        </g>
      </svg>

      <svg
        strokeMiterlimit={10}
        strokeWidth="3px"
        fill="none"
        stroke="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 700.26 186"
        className={classNames('640:hidden', className)}
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <polygon
              className={bottomClassName}
              fill="currentColor"
              stroke="currentColor"
              points="0.13 142 0.13 186 700.13 186 700.13 49 0.13 142"
            />
            <polygon
              className={topClassName}
              fill="currentColor"
              stroke="currentColor"
              points="0.13 0 0.13 142 700.13 49 700.13 0 0.13 0"
            />
            <line
              className={lineClassName}
              fill="currentColor"
              stroke="currentColor"
              x1="700.13"
              y1="48"
              x2="0.13"
              y2="141"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default DiagonalLineRightSeparator;
