import React from 'react';
import { SectionBasicWrapper } from './wrappers/section-basic-wrapper';
import { useLanguageContext } from '../../contexts/language-context';
import {
  Icon,
  IconKey,
  isLastIndex,
  SchemaTypes,
  FadeInAnimation,
} from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import Separator from './separators/index';

export interface ContactsSectionProps {
  section: SchemaTypes['ContactsSectionDto'];
}

const ContactsSection = ({
  section: {
    items,
    backgroundColor,
    headingText,
    subHeadingText,
    topSeparator,
    bottomSeparator,
    scrollId,
  },
}: ContactsSectionProps) => {
  const { activeLanguage } = useLanguageContext();

  const sortedItems = [...items].sort(
    (a, b) => a.displayOrder - b.displayOrder,
  );

  return (
    <>
      <Separator
        style={topSeparator.style}
        metadata={topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={headingText?.[activeLanguage]}
        subHeadingText={subHeadingText?.[activeLanguage]}
        wrapperClassName={backgroundColor}
        id={scrollId ? scrollId[activeLanguage] : undefined}
      >
        <FadeInAnimation className="flex flex-col items-center 900:flex-row 900:items-stretch w-full">
          {sortedItems.map((item, index) => (
            <div
              className={classNames('flex flex-col items-center w-full px-5', {
                '900:border-r-2 border-gray-300 mb-7 pb-7 900:mb-0':
                  !isLastIndex(index, items),
              })}
              key={index}
            >
              <div className="bg-primary-200 p-4 rounded-full mb-4">
                <Icon
                  icon={item.icon as IconKey}
                  className="flex-shrink-0 w-10 h-10 text-primary-main"
                />
              </div>
              <div>
                {item.rows.map((row, idx) => {
                  if (row.to) {
                    return (
                      <a
                        className="text-primary-main group flex items-center justify-center"
                        key={idx}
                        href={row.to}
                      >
                        {row.icon && (
                          <Icon
                            icon={row.icon as IconKey}
                            className="group-hover:text-primary-hover flex-shrink-0 w-5 h-5 mr-1"
                          />
                        )}

                        <p className="group-hover:text-primary-hover font-semibold whitespace-nowrap">
                          {row.text[activeLanguage]}
                        </p>
                      </a>
                    );
                  } else {
                    return (
                      <div
                        className="flex items-center justify-center"
                        key={idx}
                      >
                        {row.icon && (
                          <Icon
                            icon={row.icon as IconKey}
                            className="flex-shrink-0 w-5 h-5 mr-1"
                          />
                        )}

                        <p className="font-semibold whitespace-nowrap">
                          {row.text[activeLanguage]}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ))}
        </FadeInAnimation>
      </SectionBasicWrapper>
      <Separator
        style={bottomSeparator.style}
        metadata={bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default ContactsSection;
