import React from 'react';

export const GoogleLogo = ({ className }: { className: string }) => (
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.2978 36.2613L11.2092 44.0583L3.5754 44.2198C1.29403 39.9883 0 35.147 0 30.0023C0 25.0274 1.20989 20.336 3.3545 16.2051H3.35614L10.1523 17.4511L13.1295 24.2065C12.5064 26.0231 12.1667 27.9731 12.1667 30.0023C12.167 32.2046 12.5659 34.3146 13.2978 36.2613Z"
      fill="#FBBB00"
    />
    <path
      d="M59.4755 24.3975C59.8201 26.2123 59.9997 28.0865 59.9997 30.002C59.9997 32.1499 59.7739 34.2451 59.3437 36.2661C57.8833 43.1432 54.0671 49.1484 48.7807 53.398L48.7791 53.3963L40.2189 52.9596L39.0074 45.3966C42.5152 43.3394 45.2565 40.12 46.7006 36.2661H30.6582V24.3975H46.9346H59.4755Z"
      fill="#518EF8"
    />
    <path
      d="M48.7841 53.395L48.7858 53.3966C43.6445 57.5291 37.1134 60.0018 30.0038 60.0018C18.5787 60.0018 8.64538 53.6159 3.57812 44.2183L13.3005 36.2598C15.834 43.0215 22.3568 47.8349 30.0038 47.8349C33.2907 47.8349 36.37 46.9464 39.0123 45.3952L48.7841 53.395Z"
      fill="#28B446"
    />
    <path
      d="M49.1478 6.90681L39.4287 14.8637C36.694 13.1543 33.4614 12.1668 29.9982 12.1668C22.1781 12.1668 15.5333 17.2011 13.1266 24.2053L3.3532 16.2039H3.35156C8.34463 6.57715 18.4032 0 29.9982 0C37.2775 0 43.9519 2.59298 49.1478 6.90681Z"
      fill="#F14336"
    />
  </svg>
);
