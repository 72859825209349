import React, { useMemo } from 'react';
import { SectionBasicWrapper } from './wrappers/section-basic-wrapper';
import { useLanguageContext } from '../../contexts/language-context';
import {
  Icon,
  SchemaTypes,
  FadeInAnimation,
  isLastIndex,
  FishTypes,
} from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import Separator from './separators/index';
import Image from '../image';
import { FacilitiesLogo } from '../svgs/facilities';

interface FishTypeCardProps {
  fishType: SchemaTypes['FishTypeDto'];
}

export const fishTypesData = [
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/carp.png',
    name: { en: 'Carp', hu: 'Ponty', ro: 'Crap' },
    key: FishTypes.CARP,
    isCarnivore: false, // omnivorous
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/brown-trout.png',
    name: { en: 'Brown Trout', hu: 'Pisztráng', ro: 'Păstrăv' },
    key: FishTypes.BROWN_TROUT,
    isCarnivore: true, // omnivorous
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/grass-carp.png',
    name: { en: 'Grass carp', hu: 'Amur', ro: 'Amur' },
    key: FishTypes.GRASS_CARP,
    isCarnivore: false,
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/sturgeon.png',
    name: {
      en: 'Sturgeon',
      hu: 'Tokhal',
      ro: 'Sturion',
    },
    key: FishTypes.STURGERON,
    isCarnivore: true,
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/northern-pike.png',
    name: { en: 'Northern pike', hu: 'Csuka', ro: 'Știucă' },
    key: FishTypes.NORTHERN_PIKE,
    isCarnivore: true,
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/catfish.png',
    name: { en: 'Catfish', hu: 'Harcsa', ro: 'Somn' },
    key: FishTypes.CATFISH,
    isCarnivore: false, //Some can be carnivores, herbivores, omnivores, or even limnivores
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/african-catfish.png',
    name: { en: 'African catfish', hu: 'Afrikai harcsa', ro: 'Somn african' },
    key: FishTypes.AFRICAN_CATFISH,
    isCarnivore: false, // omnivore / highly carnivore
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/zander.png',
    name: { en: 'Zander', hu: 'Süllő', ro: 'Șalău' },
    key: FishTypes.ZANDER,
    isCarnivore: true,
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/perch.png',
    name: { en: 'Perch', hu: 'Sügér', ro: 'Biban' },
    key: FishTypes.PERCH,
    isCarnivore: true,
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/crucian-carp.png',
    name: { en: 'Crucian carp', hu: 'Kárász', ro: 'Caras' },
    key: FishTypes.CRUCIAN_CARP,
    isCarnivore: false, // omnivorous
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/common-bream.png',
    name: { en: 'Common bream', hu: 'Dévérkeszeg', ro: 'Plătică' },
    key: FishTypes.COMMON_BREAM,
    isCarnivore: false, // omnivore / highly carnivore
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/tench.png',
    name: { en: 'Tench', hu: 'Compó', ro: 'Lin' },
    key: FishTypes.TENCH,
    isCarnivore: false, // omnivore / highly carnivore
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/ruffle.png',
    name: { en: 'Ruffle', hu: 'Durbincs', ro: 'Ghiborț' },
    key: FishTypes.RUFFLE,
    isCarnivore: true, // not 100%
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/squalius-cephalus.png',
    name: { en: 'Squalius cephalus', hu: 'Domolykó', ro: 'Clean' },
    key: FishTypes.SQUALIUS_CEPHALUS,
    isCarnivore: false, // omnivorous
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/pumpkinseed.png',
    name: { en: 'Pumpkinseed', hu: 'Naphal', ro: 'Sorete' },
    key: FishTypes.PUMPKINSEED,
    isCarnivore: true,
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/silver-carp.png',
    name: { en: 'Silver carp', hu: 'Busa', ro: 'Fitofag (Sânger)' },
    key: FishTypes.SILVER_CARP,
    isCarnivore: false, //omnivorous - not 100%
  },
  {
    imgUrl:
      'https://ags-public-assets.s3.eu-central-1.amazonaws.com/fish-types/rudd.png',
    name: { en: 'Rudd', hu: 'Vörösszárnyú keszeg', ro: 'Babușcă' },
    key: FishTypes.RUDD,
    isCarnivore: false, //omnivorous
  },
];

const FishTypeCard = ({ fishType }: FishTypeCardProps) => {
  const { activeLanguage } = useLanguageContext();

  const fishTypeConstants = useMemo(() => {
    const foundFishType = fishTypesData.find((ft) => ft.key === fishType.key);

    return foundFishType || null;
  }, [fishType.key]);

  if (!fishTypeConstants) {
    return null;
  }

  return (
    <div className="flex flex-col min-w-[170px] max-w-[170px] rounded-md shadow-lg overflow-hidden relative">
      {/* @ts-ignore TODO: fix type */}
      <Image
        alt={fishTypeConstants.name[activeLanguage]}
        useNativeImage={true}
        src={fishTypeConstants.imgUrl}
        className="object-contain"
      />
      {fishType.recordWeightText?.[activeLanguage] && (
        <div className="absolute top-0 left-0 flex items-center space-x-1">
          <div className="flex items-center text-sm bg-primary-100 text-primary-800 rounded-md px-0.5 font-semibold">
            <Icon icon="ribbon" className="text-base" />
            {fishType.recordWeightText?.[activeLanguage]}
          </div>
        </div>
      )}
      <p className="px-2 py-0.5 font-semibold text-gray-900 whitespace-nowrap items-center flex">
        {fishTypeConstants.name[activeLanguage]}
      </p>
    </div>
  );
};

export interface FishingSpecificSectionProps {
  section: SchemaTypes['FishingSpecificSectionDto'];
}

const FishingSpecificSection = ({
  section: {
    fishDensityText,
    fishFeedingText,
    lakeAreaText,
    lakeDepthText,
    lakeImage,
    bottomSeparator,
    topSeparator,
    headingText,
    subHeadingText,
    scrollId,
    backgroundColor,
    pierDetails,
    fishTypes,
    location,
    withExtraInfoAboutFishKeeping,
    style,
    facilities,
  },
}: FishingSpecificSectionProps) => {
  const { activeLanguage, translations } = useLanguageContext();

  const sortedFacilities = useMemo(
    () =>
      [...(facilities || [])].sort((a, b) => a.displayOrder - b.displayOrder),
    [facilities],
  );

  const sortedAllFishTypes = useMemo(
    () => [...fishTypes].sort((a, b) => a.displayOrder - b.displayOrder),
    [fishTypes],
  );

  const sortedNonPredatorFishTypes = useMemo(
    () =>
      sortedAllFishTypes
        .filter((fishType) => {
          const foundFishType = !fishTypesData.find(
            (ft) => ft.key === fishType.key,
          )?.isCarnivore;

          return foundFishType || null;
        })
        .filter(Boolean),
    [sortedAllFishTypes],
  );

  const sortedPredatorFishTypes = useMemo(
    () =>
      sortedAllFishTypes
        .filter((fishType) => {
          const foundFishType = fishTypesData.find(
            (ft) => ft.key === fishType.key,
          )?.isCarnivore;

          return foundFishType || null;
        })
        .filter(Boolean),
    [sortedAllFishTypes],
  );

  const sortedFishForSale = useMemo(
    () =>
      [...sortedAllFishTypes]
        .filter((fishType) => !!fishType.isForSale)
        .map((fishType) => ({
          ...fishType,
          name: fishTypesData.find(
            (fishTypeData) => fishTypeData.key === fishType.key,
          ).name,
        })),
    [sortedAllFishTypes],
  );

  const numberOfFishTypes = useMemo(() => fishTypes.length, [fishTypes]);

  const sortedPierTypes = useMemo(
    () => [...pierDetails].sort((a, b) => a.displayOrder - b.displayOrder),
    [pierDetails],
  );

  const numberOfPiers = useMemo(
    () => pierDetails.reduce((result, pier) => result + pier.numberOfPiers, 0),
    [pierDetails],
  );

  const isOnlyOnePierType = useMemo(
    () => pierDetails.length === 1,
    [pierDetails],
  );

  const fishTypeCardsContainerClasses =
    'pb-2 pr-[16px] 640:pr-[24px] 768:pr-0 mt-4 gap-x-2 max-w-full flex-nowrap overflow-x-auto 768:pb-0 768:gap-x-4 768:gap-y-4 768:ml-0 768:mr-0 768:left-0 768:right-0 768:static 768:w-auto 768:flex-wrap 768:overflow-x-visible';

  const piersBreakdown = useMemo(
    () => (
      <>
        <h4 className="mb-1 text-3xl font-semibold text-center text-gray-900 768:text-2xl 768:text-left 768:mb-3">
          {translations?.fishingSpots?.[activeLanguage]}
          <span className="hidden ml-1 text-gray-900 768:inline-flex">
            {!isOnlyOnePierType
              ? `(${numberOfPiers} ${translations?.inTotal?.[activeLanguage]})`
              : ''}
          </span>
        </h4>
        <p className="flex self-center mb-4 text-lg text-center text-gray-700 768:hidden">
          {numberOfPiers} {translations?.inTotal?.[activeLanguage]}
        </p>
        <div className="flex flex-col items-center space-y-3 768:items-start">
          {sortedPierTypes.map(
            (
              {
                capacity,
                numberOfPiers,
                pierNumbers,
                withCabana,
                capacitySuffixText,
                forXPersonsText,
              },
              index,
            ) => (
              <div
                key={index}
                className="flex-col w-full p-3 bg-gray-100 rounded-md shadow-md"
              >
                <div className="flex items-center mb-1">
                  {Array(capacity)
                    .fill('user')
                    .map((icon, index) => (
                      <Icon key={index} className="text-gray-900" icon={icon} />
                    ))}
                  {withCabana && (
                    <Icon
                      className="ml-2 text-xl text-gray-900"
                      icon="pierWithLodges"
                    />
                  )}
                </div>
                <p className="text-gray-900">
                  {forXPersonsText[activeLanguage]}:{' '}
                  <span className="font-semibold text-gray-900">
                    {numberOfPiers} {capacitySuffixText[activeLanguage]}
                  </span>
                </p>
                {pierNumbers?.[activeLanguage] && (
                  <div className="flex pt-1 mt-1 border-t border-gray-700">
                    <p className="text-sm text-gray-900">
                      {pierNumbers[activeLanguage]}
                    </p>
                  </div>
                )}
              </div>
            ),
          )}
        </div>
      </>
    ),
    [sortedPierTypes, isOnlyOnePierType, numberOfPiers, activeLanguage],
  );

  const isWithFullWidthImage = style === 'full-width-image';

  return (
    <>
      <Separator
        style={topSeparator.style}
        metadata={topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={headingText?.[activeLanguage]}
        subHeadingText={subHeadingText?.[activeLanguage]}
        wrapperClassName={backgroundColor}
        id={scrollId ? scrollId[activeLanguage] : undefined}
      >
        <FadeInAnimation className="flex flex-col w-full">
          <div className="flex flex-col mb-12">
            <div
              className={classNames('flex flex-col items-center', {
                '1024:items-start 1024:flex-row': !isWithFullWidthImage,
                'w-full': isWithFullWidthImage,
              })}
            >
              {lakeImage && (
                <Image
                  // @ts-ignore - it exists but too hard to type
                  image={lakeImage?.gatsbyImageData}
                  src={lakeImage?.url}
                  alt=""
                  className={classNames('w-full rounded-md mb-6', {
                    '1024:w-1/2 1024:mr-8 1024:mb-0': !isWithFullWidthImage,
                  })}
                  imgClassName="w-full rounded-md"
                />
              )}
              <div className="flex flex-col-reverse 768:flex-row 768:self-start">
                <div className="flex flex-col 768:mr-6">{piersBreakdown}</div>
                <div className="flex flex-col mb-12 space-y-6">
                  {lakeAreaText?.[activeLanguage] && (
                    <div className="flex items-center">
                      <div className="flex justify-center items-center rounded-full w-[50px] h-[50px] border-2 border-primary-main mr-3">
                        <Icon
                          className="text-2xl text-primary-main"
                          icon="area"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h4 className="text-lg font-semibold text-gray-900">
                          {translations?.area?.[activeLanguage]}
                        </h4>
                        <p className="text-xl text-gray-900 whitespace-nowrap">
                          {lakeAreaText[activeLanguage]}
                        </p>
                      </div>
                    </div>
                  )}
                  {lakeDepthText?.[activeLanguage] && (
                    <div className="flex items-center">
                      <div className="flex justify-center items-center rounded-full w-[50px] h-[50px] border-2 border-primary-main mr-3 flex-shrink-0">
                        <Icon
                          className="flex-shrink-0 text-3xl text-primary-main"
                          icon="downArrow"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h4 className="text-lg font-semibold text-gray-900">
                          {translations?.depth?.[activeLanguage]}
                        </h4>
                        <p className="text-xl text-gray-900 whitespace-nowrap">
                          {lakeDepthText[activeLanguage]}
                        </p>
                      </div>
                    </div>
                  )}
                  {location?.locationText?.[activeLanguage] && (
                    <div className="flex">
                      <div className="flex justify-center items-center rounded-full w-[50px] h-[50px] border-2 border-primary-main mr-3 mt-1 flex-shrink-0">
                        <Icon
                          className="flex-shrink-0 text-3xl text-primary-main"
                          icon="location"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h4 className="text-lg font-semibold text-gray-900">
                          {translations.location?.[activeLanguage]}
                        </h4>
                        <p className="text-xl text-gray-900 whitespace-nowrap">
                          {location.locationText?.[activeLanguage]} (
                          {location.disctrictText?.[activeLanguage]})
                        </p>
                        <a
                          className="text-lg font-semibold text-primary-main"
                          href={
                            location.googleMapsLink[activeLanguage] as string
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translations.seeOnMap?.[activeLanguage]}
                        </a>
                      </div>
                    </div>
                  )}
                  {facilities && sortedFacilities.length > 0 && (
                    <div className="flex">
                      <div className="flex justify-center items-center rounded-full w-[50px] h-[50px] border-2 border-primary-main mr-3 mt-1 flex-shrink-0">
                        <FacilitiesLogo className="flex-shrink-0 text-3xl text-primary-main" />
                      </div>
                      <div className="flex flex-col">
                        <h4 className="text-lg font-semibold text-gray-900">
                          {translations.facilities?.[activeLanguage]}
                        </h4>
                        <div className="flex flex-col space-y-1">
                          {sortedFacilities.map((facility) => (
                            <p className="text-gray-900">
                              - {facility.text?.[activeLanguage]}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col full-bleed pl-[16px] 640:pl-[24px] 768:!pl-0 768:!static 768:!ml-0 768:!mr-0 768:!w-auto">
            <h3 className="mb-1 text-3xl font-semibold text-center text-gray-900 768:text-2xl 768:text-left">
              {translations?.fishSpecies?.[activeLanguage]}{' '}
              <span className="hidden 768:inline-flex">{`(${numberOfFishTypes} ${translations?.inTotal?.[activeLanguage]})`}</span>
            </h3>
            <p className="flex self-center mb-4 text-lg text-center text-gray-700 768:hidden">
              {numberOfFishTypes} {translations?.inTotal?.[activeLanguage]}
            </p>
            <p className="text-lg font-semibold text-gray-900">
              {translations?.peaceful?.[activeLanguage]}
            </p>
            <div
              className={classNames(
                'flex flex-wrap',
                fishTypeCardsContainerClasses,
              )}
            >
              {sortedNonPredatorFishTypes.map((fishType, index) => (
                <FishTypeCard key={index} fishType={fishType} />
              ))}
            </div>
            {sortedPredatorFishTypes?.length > 0 && (
              <>
                <p className="mt-8 text-lg font-semibold text-gray-900">
                  {translations?.predators?.[activeLanguage]}
                </p>
                <div
                  className={classNames(
                    'flex flex-wrap',
                    fishTypeCardsContainerClasses,
                  )}
                >
                  {sortedPredatorFishTypes.map((fishType, index) => (
                    <FishTypeCard key={index} fishType={fishType} />
                  ))}
                </div>
              </>
            )}
          </div>
          {withExtraInfoAboutFishKeeping && (
            <div className="flex max-w-3xl mt-6 text-center 768:text-left">
              <p
                className={classNames('text-lg text-gray-900', {
                  'font-semibold': sortedFishForSale.length === 0,
                })}
              >
                {sortedFishForSale.length === 0 ? '🚫 ' : ''}
                {
                  translations?.[
                    sortedFishForSale.length > 0
                      ? 'theFollowingFishTypesCanBeKept'
                      : 'noFishTypesCanBeKept'
                  ]?.[activeLanguage]
                }{' '}
                {sortedFishForSale.map((fish, index) => (
                  <span
                    key={index}
                    className="text-lg font-semibold text-gray-900"
                  >
                    {fish.name[activeLanguage]}
                    {isLastIndex(index, sortedFishForSale) ? '' : ', '}
                  </span>
                ))}
              </p>
            </div>
          )}
          {(fishDensityText?.[activeLanguage] ||
            fishFeedingText?.[activeLanguage]) && (
            <div className="flex flex-col self-center mt-6 space-y-4 768:self-start 768:items-center 768:space-y-0 768:space-x-8 768:flex-row">
              {/* {fishFeedingText?.[activeLanguage] && (
                <div className="flex items-center">
                  <div className="relative flex justify-center items-center rounded-full w-[32px] h-[32px] border-2 border-primary-main mr-3">
                    <div className="absolute w-[4px] h-[4px] rounded-full border border-primary-main top-[2px] left-[10px]"></div>
                    <div className="absolute w-[4px] h-[4px] rounded-full border border-primary-main top-[4px] left-[4px]"></div>
                    <div className="absolute w-[4px] h-[4px] rounded-full border border-primary-main top-[18px] left-[3px]"></div>
                    <div className="absolute w-[4px] h-[4px] rounded-full border border-primary-main top-[21px] left-[7px]"></div>
                    <div className="absolute w-[4px] h-[4px] rounded-full border border-primary-main top-[3px] right-[5px]"></div>
                    <div className="absolute w-[4px] h-[4px] rounded-full border border-primary-main top-[7px] right-[2px]"></div>
                    <div className="absolute w-[4px] h-[4px] rounded-full border border-primary-main top-[20px] right-[3px]"></div>
                    <Icon icon="fish" className="absolute text-primary-main" />
                  </div>
                  <p className="text-xl text-gray-900">
                    {fishFeedingText?.[activeLanguage]}
                  </p>
                </div>
              )} */}
              {fishDensityText?.[activeLanguage] && (
                <div className="flex items-center">
                  <div className="flex justify-center items-center rounded-full w-[28px] h-[28px] border-2 border-primary-main mr-2">
                    <Icon
                      className="text-xl text-primary-main"
                      icon="doubleFish"
                    />
                  </div>
                  <p className="text-lg text-gray-900">
                    {fishDensityText?.[activeLanguage]}
                  </p>
                </div>
              )}
            </div>
          )}
        </FadeInAnimation>
      </SectionBasicWrapper>
      <Separator
        style={bottomSeparator.style}
        metadata={bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default FishingSpecificSection;
