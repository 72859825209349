import React from 'react';
import { SectionBasicWrapper } from './wrappers/section-basic-wrapper';
import {
  SchemaTypes,
  ScheduleSection,
} from '@reservation-app/common-used-in-web';
import { useLanguageContext } from '../../contexts/language-context';
import classNames from 'classnames';
import Separator from './separators';
import { usePageContext } from '../../contexts/page-context';

export type ScheduleSectionProps = {
  section: SchemaTypes['ScheduleSectionDto'];
};

const ScheduleSectionWithWrapper = ({ section }: ScheduleSectionProps) => {
  const { activeLanguage, translations } = useLanguageContext();
  const pageContext = usePageContext();

  const {
    scrollId,
    headingText,
    subHeadingText,
    topSeparator,
    bottomSeparator,
    backgroundColor,
  } = section;

  return (
    <>
      <Separator
        style={topSeparator.style}
        metadata={topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={headingText?.[activeLanguage]}
        subHeadingText={subHeadingText?.[activeLanguage]}
        wrapperClassName={classNames('flex-col', backgroundColor)}
        id={scrollId ? scrollId[activeLanguage] : undefined}
      >
        <ScheduleSection
          activeLanguage={activeLanguage}
          translations={translations}
          alerts={pageContext.alerts}
          schedules={pageContext.schedules}
          section={section}
        />
      </SectionBasicWrapper>
      <Separator
        style={bottomSeparator.style}
        metadata={bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default ScheduleSectionWithWrapper;
