import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { SectionBasicWrapper } from '../wrappers/section-basic-wrapper';
import {
  GallerySubsectionStyles,
  Modal,
  SchemaTypes,
  Slider,
  FadeInAnimation,
} from '@reservation-app/common-used-in-web';
import { useLanguageContext } from '../../../contexts/language-context';
import Separator from '../separators';
import Image from '../../image';
import GallerySubsections from './gallery-subsections';
export type GallerySectionProps = {
  section: SchemaTypes['GallerySectionDto'];
};

const GallerySection = ({ section }: GallerySectionProps) => {
  const [isSsrDone, setIsSsrDone] = useState(false);

  const { activeLanguage } = useLanguageContext();
  const [isGallerySubsectionModalOpen, setIsGallerySubsectionModalOpen] =
    useState(false);
  const [selectedSubsection, setSelectedSubsection] =
    useState<SchemaTypes['GallerySectionDto']['subsections'][0]>();
  const [subsectionModalStartAtIndex, setSubsectionModalStartAtIndex] =
    useState(0);

  const sortedSubsections = useMemo(
    () =>
      [...section.subsections].sort((a, b) => a.displayOrder - b.displayOrder),
    [section.subsections],
  );

  useEffect(() => {
    setIsSsrDone(true);
  }, []);

  return (
    <>
      <Separator
        style={section.topSeparator.style}
        metadata={section.topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={section.headingText?.[activeLanguage]}
        subHeadingText={section.subHeadingText?.[activeLanguage]}
        wrapperClassName={classNames(section.backgroundColor)}
        id={section.scrollId ? section.scrollId[activeLanguage] : undefined}
      >
        <FadeInAnimation
          className={classNames('flex flex-col items-center w-full')}
        >
          {section.textAboveHtml?.[activeLanguage] && (
            <div
              className="flex self-center justify-center w-full max-w-md mb-10 text-center text-gray-900 768:max-w-lg"
              dangerouslySetInnerHTML={{
                __html: section.textAboveHtml[activeLanguage],
              }}
            ></div>
          )}
          {section.images && section.images.length > 0 && (
            <Slider
              startAtIndex={1}
              isHighlightMode={false}
              itemsToShow={1}
              itemsToScroll={1}
              withArrows={true}
              arrowPosition="dots"
              breakpoints={[
                {
                  minWidth: 600,
                  config: {
                    itemsToScroll: 1,
                    itemsToShow: 3,
                    isHighlightMode: true,
                    withArrows: true,
                    arrowPosition: 'slider',
                  },
                },
              ]}
              mainContainerClassName={classNames('h-96 w-full max-w-5xl', {
                'mb-12': sortedSubsections && sortedSubsections.length > 0,
              })}
              slidesContainerClassName="rounded-md"
            >
              {section.images.map((image, index) => (
                <Image
                  key={index}
                  // @ts-ignore
                  image={image.gatsbyImageData}
                  src={image.url}
                  alt=""
                  imgClassName="rounded-md"
                  className="rounded-md shadow-md"
                />
              ))}
            </Slider>
          )}
          {sortedSubsections && sortedSubsections.length > 0 && (
            <GallerySubsections
              sortedSubsections={sortedSubsections}
              setSelectedSubsection={(subsection) =>
                setSelectedSubsection({
                  ...subsection,
                  items: [...subsection.items].sort(
                    (a, b) => a.displayOrder - b.displayOrder,
                  ),
                })
              }
              setSubsectionModalStartAtIndex={setSubsectionModalStartAtIndex}
              setIsGallerySubsectionModalOpen={setIsGallerySubsectionModalOpen}
              limitBeforeExpand={3}
            />
          )}
          {isSsrDone && (
            <Modal
              modalClassName="p-0 bg-transparent w-full"
              overlayClassName="p-0"
              isOpen={isGallerySubsectionModalOpen}
              onClose={() => {
                setIsGallerySubsectionModalOpen(false);
              }}
              restoreFocus={false}
              autoFocus={false}
              withScrollLock={false}
            >
              <Slider
                itemsToScroll={1}
                itemsToShow={1}
                mainContainerClassName="w-full 768:max-w-5xl mx-auto"
                slidesContainerClassName="768:rounded-lg"
                withArrows={true}
                arrowPosition="dots"
                startAtIndex={subsectionModalStartAtIndex}
                dotsContainerClassName="py-0.5 px-2 bg-white rounded-md shadow-md"
                breakpoints={[
                  {
                    minWidth: 768,
                    config: {
                      startAtIndex: subsectionModalStartAtIndex,
                      itemsToScroll: 1,
                      itemsToShow: 1,
                      withArrows: true,
                      arrowPosition: 'slider',
                      leftArrowClassName: 'mr-2',
                      rightArrowClassName: 'ml-2',
                    },
                  },
                ]}
              >
                {selectedSubsection?.items.map((item, index) => (
                  <div className="relative w-full" key={index}>
                    <Image
                      // @ts-ignore
                      image={item.image.gatsbyImageData}
                      src={item.image.url}
                      alt=""
                      imgClassName="768:rounded-md w-full"
                      className="w-full shadow-md 768:rounded-md"
                    />
                    {item.layout === GallerySubsectionStyles.CATCH && (
                      <div className="absolute bottom-0 flex flex-col w-full p-2 bg-white">
                        <div className="flex w-full">
                          {item.catchValue?.[activeLanguage] && (
                            <p className="mr-2 text-lg font-semibold text-gray-900">
                              {item.catchValue[activeLanguage]}
                            </p>
                          )}
                          {item.fishType?.[activeLanguage] && (
                            <p className="text-lg font-semibold text-gray-600">
                              {item.fishType[activeLanguage]}
                            </p>
                          )}
                        </div>
                        {(item.nameOfFisher || item.date) && (
                          <div className="flex justify-between w-full">
                            {item.nameOfFisher && (
                              <p className="text-gray-600">
                                {item.nameOfFisher}
                              </p>
                            )}
                            {item.date && (
                              <p className="text-gray-600">{item.date}</p>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </Slider>
            </Modal>
          )}
          {section.textBelowHtml?.[activeLanguage] && (
            <div
              className={classNames(
                'flex self-center justify-center w-full max-w-md text-center text-gray-900 768:max-w-lg 768:mt-14',
                {
                  'mt-0': sortedSubsections && sortedSubsections.length > 0,
                  '768:mt-14': !(
                    sortedSubsections && sortedSubsections.length > 0
                  ),
                },
              )}
              dangerouslySetInnerHTML={{
                __html: section.textBelowHtml[activeLanguage],
              }}
            ></div>
          )}
        </FadeInAnimation>
      </SectionBasicWrapper>
      <Separator
        style={section.bottomSeparator.style}
        metadata={section.bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default GallerySection;
