import classNames from 'classnames';
import React from 'react';

type HorizontalLineSeparatorPropTypes = {
  bottomClassName?: string;
  topClassName?: string;
  lineClassName?: string;
  className?: string;
};

const HorizontalLineSeparator = ({
  bottomClassName = 'text-white',
  topClassName = 'text-gray-100',
  lineClassName = 'text-primary-main',
  className,
}: HorizontalLineSeparatorPropTypes) => {
  return (
    <>
      <svg
        strokeMiterlimit={10}
        strokeWidth="2px"
        fill="none"
        stroke="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1400 187"
        className={classNames('hidden 640:block', className)}
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className={topClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M1440,0V93H0V0Z"
            />
            <path
              className={bottomClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M1440,93v93H0V93Z"
            />
            <path
              className={lineClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M0,93H1440"
            />
          </g>
        </g>
      </svg>

      <svg
        strokeMiterlimit={10}
        strokeWidth="3px"
        fill="none"
        stroke="none"
        className={classNames('640:hidden', className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 700.32 187"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className={topClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M700,0V93H0V0Z"
            />
            <path
              className={bottomClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M700,93v93H0V93Z"
            />
            <path
              className={lineClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M0,93H700"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default HorizontalLineSeparator;
