import {
  Button,
  GallerySubsectionStyles,
  SchemaTypes,
} from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useMemo, useState } from 'react';
import { useLanguageContext } from '../../../contexts/language-context';
import { motion, AnimatePresence } from 'framer-motion';

export interface GallerySubsectionsProps {
  subsection: SchemaTypes['GallerySectionDto']['subsections'][0];
  setSelectedSubsection: (
    subsection: SchemaTypes['GallerySectionDto']['subsections'][0],
  ) => void;
  setSubsectionModalStartAtIndex: (index: number) => void;
  setIsGallerySubsectionModalOpen: (value: boolean) => void;
  limitBeforeExpand: number;
  isLast: boolean;
  containerClassName?: string;
}

export interface GallerySubsectionCardProps {
  item: SchemaTypes['GallerySectionDto']['subsections'][0]['items'][0];
  activeLanguage: string;
}

const GallerySubsectionCard = ({
  item,
  activeLanguage,
}: GallerySubsectionCardProps) => {
  return (
    <>
      <div className="flex">
        <GatsbyImage
          // @ts-ignore
          image={item.image.gatsbyImageData}
          alt=""
          imgClassName="rounded-md"
          className="rounded-md shadow-md cursor-pointer w-72"
        />
      </div>
      {item.layout === GallerySubsectionStyles.CATCH && (
        <div className="absolute bottom-0 flex flex-col items-center w-full px-2 py-0.5 bg-white rounded-b-md overflow-none">
          <div className="flex justify-between w-full">
            <p className="mr-2 text-lg font-semibold text-gray-900 whitespace-nowrap">
              {item.catchValue?.[activeLanguage]}
            </p>
            {item.fishType?.[activeLanguage] && (
              <p className="text-lg font-semibold text-gray-600 whitespace-nowrap">
                {item.fishType[activeLanguage]}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const GallerySubsection = ({
  subsection,
  setSelectedSubsection,
  setSubsectionModalStartAtIndex,
  setIsGallerySubsectionModalOpen,
  limitBeforeExpand,
  // isLast,
  containerClassName,
}: GallerySubsectionsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { activeLanguage, translations } = useLanguageContext();

  const sortedSubsectionItems = useMemo(
    () => [...subsection.items].sort((a, b) => a.displayOrder - b.displayOrder),
    [subsection.items],
  );

  const cardsContainerClasses =
    'pr-[16px] 640:pr-[24px] 768:pr-[32px] 860:pr-0 mt-4 self-start gap-x-2 max-w-full flex-nowrap overflow-x-auto 860:gap-x-4 860:self-center 860:ml-0 860:mr-0 860:left-0 860:right-0 860:static 860:w-auto 860:flex-wrap 860:justify-center 860:overflow-x-visible';

  const dekstopCards = useMemo(
    () => (
      <div className={classNames('hidden 860:flex', cardsContainerClasses)}>
        {sortedSubsectionItems
          .slice(0, limitBeforeExpand)
          .map((item, imageIndex) => (
            <div
              key={imageIndex}
              className="relative flex flex-col flex-grow-0 flex-shrink-0 my-2 bg-white rounded-md shadow-md cursor-pointer basis-auto"
              onClick={() => {
                setSelectedSubsection(subsection);
                setSubsectionModalStartAtIndex(imageIndex);
                setIsGallerySubsectionModalOpen(true);
              }}
            >
              <GallerySubsectionCard
                item={item}
                activeLanguage={activeLanguage}
              />
            </div>
          ))}
        <AnimatePresence mode="wait">
          {isExpanded &&
            sortedSubsectionItems
              .slice(-(sortedSubsectionItems.length - limitBeforeExpand))
              .map((item, imageIndex) => (
                <motion.div
                  key={limitBeforeExpand + imageIndex + 1}
                  className="relative flex flex-col my-2 bg-white rounded-md shadow-md"
                  style={{ transformOrigin: 'top center' }}
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0 }}
                  transition={{
                    type: 'tween',
                  }}
                  onClick={() => {
                    setSelectedSubsection(subsection);
                    setSubsectionModalStartAtIndex(
                      limitBeforeExpand + imageIndex,
                    );
                    setIsGallerySubsectionModalOpen(true);
                  }}
                >
                  <GallerySubsectionCard
                    item={item}
                    activeLanguage={activeLanguage}
                  />
                </motion.div>
              ))}
        </AnimatePresence>
        {sortedSubsectionItems.length > limitBeforeExpand && (
          <div className="flex justify-center w-full mt-4">
            <Button
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
              variant="outline"
            >
              {isExpanded
                ? translations?.showLess?.[activeLanguage]
                : translations?.showMore?.[activeLanguage]}
            </Button>
          </div>
        )}
      </div>
    ),
    [
      sortedSubsectionItems,
      limitBeforeExpand,
      translations,
      isExpanded,
      activeLanguage,
    ],
  );

  const mobileCards = useMemo(
    () => (
      <div className={classNames('flex 860:hidden', cardsContainerClasses)}>
        {sortedSubsectionItems.map((item, imageIndex) => (
          <div
            key={imageIndex}
            className="relative flex flex-col flex-grow-0 flex-shrink-0 my-2 bg-white rounded-md shadow-md cursor-pointer basis-auto"
            onClick={() => {
              setSelectedSubsection(subsection);
              setSubsectionModalStartAtIndex(imageIndex);
              setIsGallerySubsectionModalOpen(true);
            }}
          >
            <GallerySubsectionCard
              item={item}
              activeLanguage={activeLanguage}
            />
          </div>
        ))}
      </div>
    ),
    [sortedSubsectionItems, translations, activeLanguage],
  );

  return (
    <div
      className={classNames(
        'flex flex-col self-start items-center full-bleed pl-[16px] 640:pl-[24px] 768:pl-[32px] 860:w-full 860:self-center 860:static 860:ml-0 860:mr-0 860:left-0 860:right-0 860:pl-0 mb-12 860:mb-16',
        containerClassName,
      )}
    >
      {subsection.header?.[activeLanguage] && (
        <h3 className="mb-2 text-xl text-center max-w-sm font-semibold text-gray-900 860:mb-0 pr-[16px] 640:pr-[24px] 768:pr-[32px] 860:pr-0">
          {subsection.header[activeLanguage]}
        </h3>
      )}
      {subsection.description?.[activeLanguage] && (
        <div
          className="text-base text-center text-gray-700 pr-[16px] 640:pr-[24px] 768:pr-[32px] 860:pr-0"
          dangerouslySetInnerHTML={{
            __html: subsection.description[activeLanguage],
          }}
        />
      )}
      {mobileCards}
      {dekstopCards}
    </div>
  );
};

export default GallerySubsection;
