import React from 'react';
import { useLanguageContext } from '../../../contexts/language-context';
import classNames from 'classnames';
import {
  SchemaTypes,
  Icon,
  IconKey,
  isLastIndex,
} from '@reservation-app/common-used-in-web';
import LocalizedLink from '../../localized-link';
import Image from '../../image';
import { usePageContext } from '../../../contexts/page-context';
import {
  reservationPageLanguageMapping,
  verifyAvailabilityPageLanguageMapping,
} from '../../../static-content';

export interface HeroSectionProps {
  section: SchemaTypes['HeroSectionDto'] & {
    image: {
      gatsbyImageData: any;
    };
  };
}

const HeroSectionWithStatistics = ({ section }: HeroSectionProps) => {
  const { activeLanguage } = useLanguageContext();
  const { reservationConfig, availabilityCheckConfig } = usePageContext();

  const sortedStatistics = [...section.statistics].sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  return (
    <section className="flex flex-col max-h-full 768:min-h-[min(80vh,600px)] min-h-[min(80vh,450px)] pt-16 768:pt-16 relative">
      <Image
        alt="hero"
        loading="eager"
        image={section.image.gatsbyImageData}
        className="!absolute top-0 bottom-0 right-0 left-0 -z-10 brightness-50"
        src={section.image.url}
      />
      <div className="flex w-full h-full pt-2 768:pt-12 1280:pt-20 custom-container mx-auto">
        <div className="flex flex-col justify-center items-center 768:items-start w-full 768:max-w-2xl text-center 768:text-left">
          <h1 className="text-2xl 768:text-4xl font-semibold text-white mb-4">
            {section.headingText[activeLanguage]}
          </h1>
          <h2 className="text-base 768:text-xl text-white mb-6 768:mb-10">
            {section.subHeadingText[activeLanguage]}
          </h2>
          <div className="flex flex-col 768:flex-row">
            {reservationConfig.isActive && (
              <LocalizedLink
                to={`/${reservationPageLanguageMapping[activeLanguage]}`}
                className="btn-md btn-primary-filled 768:btn-lg 768:h-[46px] text-white hover:text-white mb-4 768:mb-0 768:mr-4"
              >
                {section.callToActionText[activeLanguage]}
              </LocalizedLink>
            )}
            {availabilityCheckConfig.isActive && (
              <LocalizedLink
                to={`/${verifyAvailabilityPageLanguageMapping[activeLanguage]}`}
                className="btn-md btn-primary-outline !text-primary-main hover:bg-primary-100 hover:text-primary-900 768:btn-lg 768:h-[46px]"
              >
                {section.secondaryCallToActionText[activeLanguage]}
              </LocalizedLink>
            )}
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 bg-gray-100 flex py-4 h-28 w-full mt-auto">
        {sortedStatistics.map((stat, index) => (
          <div
            key={stat.icon}
            className={classNames(
              'flex flex-col justify-start items-center px-4 768:px-0 flex-grow border-r w-0 border-gray-400',
              {
                'border-none': isLastIndex(index, section.statistics),
              },
            )}
          >
            <div className="flex items-center justify-center pt-3 mb-2">
              <Icon
                icon={stat.icon as IconKey}
                className="mr-2 text-xl 768:text-2xl text-gray-600"
              />
              <p className="text-lg 768:text-xl font-semibold text-gray-600">
                {stat.number}
              </p>
            </div>
            <p className="text-sm 768:text-base uppercase text-gray-600 text-center">
              {stat.label[activeLanguage]}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HeroSectionWithStatistics;
