import React from 'react';
import { SectionBasicWrapper } from '../wrappers/section-basic-wrapper';
import { useLanguageContext } from '../../../contexts/language-context';
import {
  SchemaTypes,
  TestimonialsSectionStyles,
} from '@reservation-app/common-used-in-web';
import Separator from '../separators/index';
import Basic from './basic';
import AlternativeTestimonialsSection from './alternative';

export interface TestimonialsSectionProps {
  section: SchemaTypes['TestimonialsSectionDto'];
}

const TestimonialsSection = ({
  section: {
    testimonials,
    backgroundColor,
    style,
    headingText,
    subHeadingText,
    topSeparator,
    bottomSeparator,
    scrollId,
  },
}: TestimonialsSectionProps) => {
  const { activeLanguage } = useLanguageContext();

  const sortedTestimonials = [...testimonials].sort(
    (a, b) => a.displayOrder - b.displayOrder,
  );

  let content: React.ReactNode;

  switch (style) {
    case TestimonialsSectionStyles.ALTERNATIVE:
      content = (
        <AlternativeTestimonialsSection
          testimonials={sortedTestimonials}
          activeLanguage={activeLanguage}
        />
      );
      break;
    default: {
      content = (
        <Basic
          testimonials={sortedTestimonials}
          activeLanguage={activeLanguage}
        />
      );
    }
  }

  return (
    <>
      <Separator
        style={topSeparator.style}
        metadata={topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={headingText?.[activeLanguage]}
        subHeadingText={subHeadingText?.[activeLanguage]}
        wrapperClassName={backgroundColor}
        id={scrollId ? scrollId[activeLanguage] : undefined}
      >
        {content}
      </SectionBasicWrapper>
      <Separator
        style={bottomSeparator.style}
        metadata={bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default TestimonialsSection;
