import React, { useEffect, useState } from 'react';
import { isDevEnv } from '@reservation-app/common-used-in-web';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import classNames from 'classnames';

const Image = (
  props: GatsbyImageProps & { src?: string; useNativeImage?: boolean },
) => {
  const [isEager, setIsEager] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsEager(true);
    }, 1000);
  }, []);

  if (props.useNativeImage) {
    return (
      <img
        className={props.imgClassName}
        src={props.src}
        style={props.imgStyle}
        loading={isEager ? 'eager' : 'lazy'}
      />
    );
  }

  if (isDevEnv) {
    return (
      <div className={classNames('inline overflow-hidden', props.className)}>
        <img
          className={props.imgClassName}
          src={props.src}
          style={props.imgStyle}
          loading={isEager ? 'eager' : 'lazy'}
        />
      </div>
    );
  }

  return <GatsbyImage {...props} />;
};

export default Image;
