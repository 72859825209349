import React from 'react';
import { SectionBasicWrapper } from './wrappers/section-basic-wrapper';
import { useLanguageContext } from '../../contexts/language-context';
import {
  FadeInAnimation,
  SchemaTypes,
} from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import Separator from './separators/index';
import Image from '../image';

export interface AlternatingContentSectionProps {
  section: SchemaTypes['AlternatingContentSectionDto'];
  isFirstSectionAfterLandingPage: boolean;
}

const AlternatingContentSection = ({
  section: {
    items,
    backgroundColor,
    headingText,
    subHeadingText,
    topSeparator,
    bottomSeparator,
    scrollId,
  },
  isFirstSectionAfterLandingPage,
}: AlternatingContentSectionProps) => {
  const { activeLanguage } = useLanguageContext();

  const sortedItems = [...items].sort(
    (a, b) => a.displayOrder - b.displayOrder,
  );

  return (
    <>
      <Separator
        style={topSeparator.style}
        metadata={topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={headingText?.[activeLanguage]}
        subHeadingText={subHeadingText?.[activeLanguage]}
        wrapperClassName={classNames(backgroundColor, {
          'pt-6 768:pt-12': isFirstSectionAfterLandingPage,
        })}
        id={scrollId ? scrollId[activeLanguage] : undefined}
      >
        <FadeInAnimation className="flex flex-col my-6 768:my-10 space-y-14 768:space-y-20">
          {sortedItems.map((item, index) => {
            const isEven = (index + 1) % 2 === 0;

            let image: any;
            let imageSize: number;
            if (item.longImage) {
              image = item.longImage;
              imageSize = 9 / 16;
            }

            if (item.horizontalImage) {
              image = item.horizontalImage;
              imageSize = 4 / 3;
            }

            if (item.squareImage) {
              image = item.squareImage;
              imageSize = 1 / 1;
            }

            if (item.wideImage) {
              image = item.wideImage;
              imageSize = 16 / 9;
            }

            return (
              <div
                className={classNames(
                  'flex flex-col 768:flex-row items-center',
                  {
                    '768:flex-row-reverse': isEven,
                  },
                )}
                key={index}
              >
                <Image
                  // @ts-ignore - it exists but too hard to type
                  image={image?.gatsbyImageData}
                  src={image?.url}
                  alt=""
                  imgClassName="rounded-lg"
                  className={classNames(
                    'rounded-lg mb-6 768:mb-0 flex-shrink-0',
                    {
                      'w-56 768:w-96': imageSize === 4 / 3,
                      'w-56 768:w-[340px] 800:w-[370px] 1024:w-[460px]':
                        imageSize === 16 / 9,
                      'w-28 768:w-48': imageSize === 9 / 16,
                      'w-48 768:w-72': imageSize === 1 / 1,
                    },
                  )}
                />
                <div
                  className={classNames(
                    'flex flex-col justify-center max-w-lg flex-shrink',
                    { '768:mr-14': isEven, '768:ml-14': !isEven },
                  )}
                >
                  <h2 className="text-center 768:text-left text-xl 768:text-4xl text-gray-900 font-semibold mb-3">
                    {item.headingText[activeLanguage]}
                  </h2>
                  <p
                    className="text-center 768:text-left"
                    dangerouslySetInnerHTML={{
                      __html: item.bodyText[activeLanguage],
                    }}
                  ></p>
                </div>
              </div>
            );
          })}
        </FadeInAnimation>
      </SectionBasicWrapper>
      <Separator
        style={bottomSeparator.style}
        metadata={bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default AlternatingContentSection;
