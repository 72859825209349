import classNames from 'classnames';
import React from 'react';

type FootballUpwardsSeparatorPropTypes = {
  className?: string;
  lineClassName?: string;
  topClassName?: string;
  bottomClassName?: string;
};

const FootballUpwardsSeparator = ({
  bottomClassName = 'text-white',
  topClassName = 'text-white',
  lineClassName = 'text-primary-main',
  className,
}: FootballUpwardsSeparatorPropTypes) => {
  return (
    <>
      <svg
        strokeMiterlimit={10}
        strokeWidth="4px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440.23 186"
        className={classNames('hidden 640:block', className)}
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className={bottomClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M1440.23,93v93H.23V93Z"
            />
            <path
              className={topClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M1440.23,0V93H.23V0Z"
            />
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M0,92.65H1440"
            />
            <path
              className={lineClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M711.77,91.62a8.23,8.23,0,0,1,16.46,0"
            />
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M643.89,90.59c1-41.35,36.61-76.12,78.17-76.12"
            />
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M796.11,91.62C795.5,50.27,762,14.47,720,14.47"
            />
          </g>
        </g>
      </svg>

      <svg
        strokeMiterlimit={10}
        strokeWidth="4px"
        className={classNames('640:hidden', className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 700 186"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M350,16c.47,0,.95,0,1.42,0"
            />
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M440,92c0,.34,0,.69,0,1"
            />
            <path
              className={bottomClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M0,186V92H338.8v0h22.4v0H700v94Z"
            />
            <path
              className={topClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M0,92V0H700V92H440C438.74,51.51,400,16.68,351.42,16c-47.18.65-87.52,34.91-89.37,76Z"
            />
            <path
              className={topClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M262.05,92c1.85-41,42.19-75.3,89.37-75.95C400,16.68,438.74,51.51,440,92H361.2v0H338.8v0Z"
            />
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M262.05,92c1.85-41,42.19-75.3,89.37-75.95C400,16.68,438.74,51.51,440,92H361.2v0H338.8v0Z"
            />
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M700,92H0"
            />

            <path
              className={lineClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M338.8,92v0c0-4.39,5.05-8,11.2-8s11.18,3.58,11.2,8v0Z"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default FootballUpwardsSeparator;
