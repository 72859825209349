import { FadeInAnimation } from '@reservation-app/common-used-in-web';
import { SchemaTypes, Slider } from '@reservation-app/common-used-in-web';
import React from 'react';
import Image from '../../image';

export interface BasicTestimonialsSectionProps {
  testimonials: SchemaTypes['TestimonialsSectionDto']['testimonials'];
  activeLanguage: string;
}

const Basic = ({
  testimonials,
  activeLanguage,
}: BasicTestimonialsSectionProps) => {
  return (
    <FadeInAnimation className="flex flex-col w-full items-center">
      <Slider
        autoPlay
        autoPlayInterval={6000}
        withArrows={true}
        arrowPosition="slider"
        leftArrowClassName="mr-2"
        rightArrowClassName="ml-2"
        mainContainerClassName="w-full max-w-2xl rounded-md"
        breakpoints={[
          {
            minWidth: 768,
            config: {
              itemsToScroll: 1,
              itemsToShow: 1,
              withArrows: true,
              arrowPosition: 'slider',
              leftArrowClassName: 'mr-2',
              rightArrowClassName: 'ml-2',
            },
          },
        ]}
      >
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center w-full max-w-2xl rounded-md shadow-md bg-white p-6 768:max-w-xl 768:flex-row"
          >
            <div className="flex flex-col justify-center items-center w-2/5 mb-4 768:mb-0">
              <Image
                // @ts-ignore
                image={testimonial.image.gatsbyImageData}
                src={testimonial.image.url}
                alt=""
                imgClassName="rounded-full"
                className="rounded-full mb-4 w-20 h-20"
              />
              <p className="font-semibold text-gray-900 text-center">
                {testimonial.title}
              </p>{' '}
              <p className="text-gray-600 text-center w-full">
                {testimonial.subTitle[activeLanguage]}
              </p>
            </div>
            <p className="ml-0 768:ml-3 text-center text-gray-900 w-full text-lg">
              "{testimonial.body[activeLanguage]}"
            </p>
          </div>
        ))}
      </Slider>
    </FadeInAnimation>
  );
};

export default Basic;
