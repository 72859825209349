import {
  SchemaTypes,
  FadeInAnimation,
} from '@reservation-app/common-used-in-web';
import classNames from 'classnames';
import React from 'react';
import { useLanguageContext } from '../../../contexts/language-context';
import Image from '../../image';

export interface BasicRecordsSectionProps {
  sortedRecords: SchemaTypes['RecordsSectionDto']['records'];
}

const BasicRecordsSection = ({ sortedRecords }: BasicRecordsSectionProps) => {
  const { translations, activeLanguage } = useLanguageContext();

  return (
    <div className="grid grid-cols-1 768:grid-cols-3 gap-7 w-10/12 max-w-5xl">
      {sortedRecords.map((record, index) => (
        <FadeInAnimation
          className="flex flex-col w-full bg-white shadow-md rounded-md md-5 768:mb-0 overflow-hidden"
          key={index}
        >
          <Image
            // @ts-ignore - it exists but too hard to type
            image={record.image.gatsbyImageData}
            src={record.image.url}
            alt=""
            imgClassName="rounded-md"
            className={classNames('w-full rounded-md')}
          />
          <div className="p-2">
            <div className="flex justify-between items-center w-full mb-2">
              <p className="font-semibold text-gray-900 text-lg">
                {record.fishName[activeLanguage]}
              </p>
              <div className=" px-2 py-1 bg-primary-light text-primary-main rounded-full font-semibold">
                {record.recordValue[activeLanguage]}
              </div>
            </div>
            <div className="text-xs w-full">
              {translations?.caughtBy?.[activeLanguage]}{' '}
              <p className="font-bold">{record.caughtBy}</p>
            </div>
          </div>
        </FadeInAnimation>
      ))}
    </div>
  );
};

export default BasicRecordsSection;
