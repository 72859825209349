import classNames from 'classnames';
import React from 'react';

type FootballDownwardsSeparatorPropTypes = {
  lineClassName?: string;
  bottomClassName?: string;
  topClassName?: string;
  className?: string;
};

const FootballDownwardsSeparator = ({
  topClassName = 'text-white',
  bottomClassName = 'text-white',
  lineClassName = 'text-primary-main',
  className,
}: FootballDownwardsSeparatorPropTypes) => {
  return (
    <>
      <svg
        strokeMiterlimit={10}
        strokeWidth="4px"
        className={classNames('hidden 640:block', className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440.23 186"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className={topClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M0,93V0H1440V93Z"
            />
            <path
              className={bottomClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M0,186V93H1440v93Z"
            />
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M1440.23,93.35H.23"
            />
            <path
              className={lineClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M728.45,94.38a8.23,8.23,0,1,1-16.45,0"
            />
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M796.34,95.41c-1,41.35-36.62,76.12-78.17,76.12"
            />
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M644.11,94.38c.62,41.35,34.15,77.15,76.12,77.15"
            />
          </g>
        </g>
      </svg>

      <svg
        strokeMiterlimit={10}
        strokeWidth="4px"
        className={classNames('640:hidden', className)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 700 186"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className={bottomClassName}
              fill="none"
              stroke="currentColor"
              d="M260,94c0-.34,0-.69,0-1"
            />
            <path
              className={topClassName}
              fill="currentColor"
              stroke="none"
              d="M700,0V94H361.2v0H338.8v0H0V0Z"
            />
            <path
              className={bottomClassName}
              fill="currentColor"
              stroke="none"
              d="M700,94v92H0V94H260c1.23,40.46,40,75.29,88.55,75.95C395.76,169.33,436.1,135.07,438,94Z"
            />
            <path
              className={bottomClassName}
              fill="currentColor"
              stroke="none"
              d="M438,94c-1.85,41-42.19,75.3-89.37,75.95C300,169.32,261.26,134.49,260,94H338.8v0h22.4v0Z"
            />
            <path
              className={lineClassName}
              fill="none"
              stroke="currentColor"
              d="M438,94c-1.85,41-42.19,75.3-89.37,75.95C300,169.32,261.26,134.49,260,94H338.8v0h22.4v0Z"
            />
            <path
              className={lineClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M0,94H700"
            />
            <path
              className={lineClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M361.2,94H338.8"
            />
            <path
              className={lineClassName}
              fill="currentColor"
              stroke="currentColor"
              d="M361.2,94v0c0,4.39-5.05,8-11.2,8s-11.18-3.58-11.2-8v0Z"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default FootballDownwardsSeparator;
