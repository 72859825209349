import React, { useMemo } from 'react';
import classNames from 'classnames';

type TwBottomSeparatorPropTypes = {
  bottomClassName?: string;
  topClassName?: string;
  lineOneClassName?: string;
  lineTwoClassName?: string;
  lineThreeClassName?: string;
  mainLineClassName?: string;
  className?: string;
};

const TwBottomSeparator = ({
  bottomClassName = 'text-gray-100',
  topClassName = 'text-white',
  lineOneClassName = 'text-gray-200',
  lineTwoClassName = 'text-gray-200',
  lineThreeClassName = 'text-gray-200',
  mainLineClassName = 'text-primary-main',
  className,
}: TwBottomSeparatorPropTypes) => {
  const isFooter = useMemo(
    () => bottomClassName?.includes('text-gray-800'),
    [bottomClassName],
  );

  return (
    <>
      {/* desktop */}
      <div
        className={classNames(
          'hidden w-full max-w-full 768:block relative',
          className,
        )}
      >
        {isFooter && topClassName && (
          <div
            className={classNames(
              'absolute w-full h-[6px] z-10 -top-[3px] left-0 right-0',
              topClassName.replace('text', 'bg'),
            )}
          ></div>
        )}
        {isFooter && bottomClassName && (
          <div
            className={classNames(
              'absolute w-full h-[6px] z-10 -bottom-[3px] left-0 right-0',
              bottomClassName.replace('text', 'bg'),
            )}
          ></div>
        )}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          // width="1440"
          // height="190"
          viewBox="0 0 1440 190"
          fill="none"
          className="w-full max-w-full min-w-full"
        >
          <g clipPath="url(#clip0_1517_3202)">
            <path
              d="M1440 190L0 190L-1.66103e-05 0L1440 -0.000125889L1440 190Z"
              fill="currentColor"
              className={topClassName}
            />
            <path
              d="M1440 94.5106C790 -55.4891 650 244.511 -8.34794e-06 94.5107L0 190L1440 190L1440 94.5106Z"
              fill="currentColor"
              className={bottomClassName}
            />
            <path
              d="M-3.78552e-06 95.4887C650 245.489 790 -54.511 1440 95.4886"
              stroke="currentColor"
              className={lineOneClassName}
              strokeWidth="2"
            />
            <path
              d="M-2.01895e-06 95.489C650 175.489 790 15.4892 1440 95.4888"
              stroke="currentColor"
              className={lineTwoClassName}
              strokeWidth="2"
            />
            <path
              d="M-7.82342e-06 95.4891C650 405.489 790 -214.511 1440 95.4889"
              stroke="currentColor"
              className={lineThreeClassName}
              strokeWidth="2"
            />
            <path
              d="M-5.80447e-06 95.4893C650 325.49 790 -134.51 1440 95.4892"
              stroke="currentColor"
              className={mainLineClassName}
              strokeWidth="2"
            />
          </g>
        </svg>
      </div>
      {/* mobile */}
      <div
        className={classNames(
          'w-full max-w-full 768:hidden relative',
          className,
        )}
      >
        {isFooter && topClassName && (
          <div
            className={classNames(
              'absolute w-full h-[6px] z-10 -top-[3px] left-0 right-0',
              topClassName.replace('text', 'bg'),
            )}
          ></div>
        )}
        {isFooter && bottomClassName && (
          <div
            className={classNames(
              'absolute w-full h-[6px] z-10 -bottom-[3px] left-0 right-0',
              bottomClassName.replace('text', 'bg'),
            )}
          ></div>
        )}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 700.32 190"
          preserveAspectRatio="none"
          fill="none"
          className="w-full max-w-full min-w-full"
        >
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              fill="currentColor"
              className={bottomClassName}
              d="M0,0H700V190H0Z"
              transform="translate(0.3)"
            />
            <path
              fill="currentColor"
              className={topClassName}
              d="M0,0H700V52.29C437.65,55.57,262.12,135.46-.3,138.68"
              transform="translate(0.3)"
            />
            <path
              d="M700,52.29c-262.26,3.28-437.75,83.12-700,86.4"
              transform="translate(0.3)"
              stroke="currentColor"
              className={lineOneClassName}
              strokeWidth="2"
            />
            <path
              stroke="currentColor"
              className={lineTwoClassName}
              d="M700,72.45C437.74,74.2,262.26,116.78,0,118.53"
              transform="translate(0.3)"
              strokeWidth="2"
            />
            <path
              stroke="currentColor"
              className={lineThreeClassName}
              d="M700,6.21C437.74,13,262.25,178,0,184.76"
              transform="translate(0.3)"
              strokeWidth="2"
            />
            <path
              stroke="currentColor"
              className={mainLineClassName}
              d="M700,29.25C437.73,34.29,262.24,156.7,0,161.73"
              transform="translate(0.3)"
              strokeWidth="2"
            />
          </g>
        </svg>
      </div>
    </>
  );
};

export default TwBottomSeparator;
