import { FadeInAnimation } from '@reservation-app/common-used-in-web';
import { Slider } from '@reservation-app/common-used-in-web';
import React from 'react';
import Image from '../../image';
import { BasicTestimonialsSectionProps } from './basic';

const AlternativeTestimonialsSection = ({
  testimonials,
  activeLanguage,
}: BasicTestimonialsSectionProps) => {
  return (
    <FadeInAnimation className="flex flex-col w-full items-center">
      <svg
        className="mb-6"
        width="86"
        height="74"
        viewBox="0 0 86 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M36.7274 11.74C31.5432 14.5789 26.972 19.1189 23.8657 24.1954C20.6643 29.4272 18.9851 35.2868 19.8617 40.4886L19.9498 41.0115L20.4666 40.8929C22.4447 40.4389 25.1557 40.3483 27.8116 40.9582C30.465 41.5676 33.0214 42.8655 34.7557 45.1499C37.0812 48.2398 38.2547 51.9982 38.2547 56.4528C38.2547 61.5685 36.6939 65.5556 33.5133 68.528L33.5105 68.5306C30.3664 71.5142 26.3456 72.9932 21.4547 72.9932C10.4166 72.9932 2.48649 63.6899 0.80135 49.2338L0.801318 49.2335C-1.84458 26.6438 13.3963 8.62891 29.1386 0.642833L36.7274 11.74ZM67.0117 40.4886L67.0998 41.0115L67.6165 40.8929C69.5947 40.4389 72.3057 40.3483 74.9616 40.9582C77.6147 41.5675 80.171 42.8653 81.9053 45.1493C84.2311 48.2394 85.4047 51.9979 85.4047 56.4528C85.4047 61.5685 83.8439 65.5556 80.6633 68.528L80.6606 68.5306C77.5164 71.5142 73.4956 72.9932 68.6047 72.9932C57.5666 72.9932 49.6365 63.6899 47.9514 49.2338L47.9513 49.2335C45.3054 26.6438 60.5463 8.62891 76.2886 0.642832L83.8774 11.74C78.6932 14.5789 74.122 19.1189 71.0157 24.1954C67.8143 29.4272 66.1351 35.2868 67.0117 40.4886Z"
          fill="#A78BFA"
        />
      </svg>
      <Slider
        autoPlay
        autoPlayInterval={6000}
        withArrows={true}
        arrowPosition="dots"
        leftArrowClassName="mr-2"
        rightArrowClassName="ml-2"
        mainContainerClassName="w-full max-w-2xl rounded-md"
        breakpoints={[
          {
            minWidth: 768,
            config: {
              withArrows: true,
              arrowPosition: 'slider',
              leftArrowClassName: 'mr-2',
              rightArrowClassName: 'ml-2',
            },
          },
        ]}
      >
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="flex flex-col items-center  w-full max-w-xs 768:max-w-lg"
          >
            <p className="font-bold mb-6 text-center text-gray-700 text-lg w-full">
              "{testimonial.body[activeLanguage]}"
            </p>
            <Image
              // @ts-ignore
              image={testimonial.image.gatsbyImageData}
              src={testimonial.image.url}
              alt=""
              imgClassName="rounded-full"
              className="rounded-full mb-4 w-20 h-20"
            />
            <p className="font-semibold text-gray-900 text-lg">
              {testimonial.title}
            </p>
            <p className=" text-gray-600 text-lg">
              {testimonial.subTitle[activeLanguage]}
            </p>
          </div>
        ))}
      </Slider>
    </FadeInAnimation>
  );
};

export default AlternativeTestimonialsSection;
