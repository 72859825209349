import React from 'react';
import classNames from 'classnames';

import { SectionBasicWrapper } from './wrappers/section-basic-wrapper';
import {
  FadeInAnimation,
  SchemaTypes,
} from '@reservation-app/common-used-in-web';
import { useLanguageContext } from '../../contexts/language-context';
import Separator from './separators';
import Image from '../image';

export type PartnersSectionProps = {
  section: SchemaTypes['PartnersSectionDto'];
};

const PartnersSection = ({ section }: PartnersSectionProps) => {
  const { activeLanguage, translations } = useLanguageContext();

  const sortedItems = [...section.items].sort((a, b) => {
    return a.displayOrder - b.displayOrder;
  });

  return (
    <>
      <Separator
        style={section.topSeparator.style}
        metadata={section.topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={section.headingText?.[activeLanguage]}
        subHeadingText={section.subHeadingText?.[activeLanguage]}
        wrapperClassName={classNames(section.backgroundColor)}
        id={section.scrollId ? section.scrollId[activeLanguage] : undefined}
      >
        <FadeInAnimation className="flex justify-center flex-wrap gap-8 w-full">
          {sortedItems.map((item) => (
            <a
              key={item.name[activeLanguage]}
              className="w-full 1280:flex-[0_1_30%] 800:flex-[1_1_45%] flex-[1_0_100%] flex flex-col max-w-md group hover:shadow-xl transition-shadow items-center bg-white rounded-lg shadow-lg p-6"
              href={item.to}
            >
              <Image
                image={
                  item.squareLogo
                    ? // @ts-ignore - it exists but too hard to type
                      item.squareLogo.gatsbyImageData
                    : // @ts-ignore - it exists but too hard to type
                      item.wideLogo.gatsbyImageData
                }
                src={
                  item.squareLogo
                    ? // @ts-ignore - it exists but too hard to type
                      item.squareLogo.url
                    : // @ts-ignore - it exists but too hard to type
                      item.wideLogo.url
                }
                alt=""
                className={classNames('mb-6', {
                  'w-[81px] h-[81px]': item.squareLogo,
                  'w-36': !item.squareLogo,
                })}
              />
              <h4 className="font-semibold text-center text-gray-900 text-xl mb-4">
                {item.name[activeLanguage]}
              </h4>
              <p className="text-gray-700 text-center mb-6">
                {item.description[activeLanguage]}
              </p>
              <p className="mt-auto font-semibold text-lg text-primary-main">
                {translations?.visitWebsite?.[activeLanguage]}
              </p>
            </a>
          ))}
        </FadeInAnimation>
      </SectionBasicWrapper>
      <Separator
        style={section.bottomSeparator.style}
        metadata={section.bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default PartnersSection;
