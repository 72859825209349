import React, { useMemo } from 'react';
import { SectionBasicWrapper } from './wrappers/section-basic-wrapper';
import { useLanguageContext } from '../../contexts/language-context';
import {
  FadeInAnimation,
  Icon,
  isLastIndex,
  SchemaTypes,
} from '@reservation-app/common-used-in-web';
import Separator from './separators';

import classNames from 'classnames';
import { usePageContext } from '../../contexts/page-context';
import { PricesSection } from '@reservation-app/common-used-in-web';
import { fishTypesData } from './fishing-specific';

export interface PricesSectionProps {
  section: SchemaTypes['PricesSectionDto'];
  isFirstSectionAfterLandingPage: boolean;
}

const PricesSectionWithWrapper = ({
  section,
  isFirstSectionAfterLandingPage,
}: PricesSectionProps) => {
  const pageContext = usePageContext();
  const { activeLanguage, translations } = useLanguageContext();

  const {
    topSeparator,
    headingText,
    subHeadingText,
    backgroundColor,
    scrollId,
    bottomSeparator,
    fishForSale,
    withExtraInfoAboutFishKeeping,
  } = section;

  const sortedFishForSale = useMemo(
    () =>
      [...(fishForSale || [])]
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .map((fishType) => ({
          ...fishType,
          name: fishTypesData.find(
            (fishTypeData) => fishTypeData.key === fishType.key,
          ).name,
        })),
    [fishForSale],
  );

  return (
    <>
      <Separator
        style={topSeparator.style}
        metadata={topSeparator.metadata}
        location="top"
      />
      <SectionBasicWrapper
        headingText={headingText?.[activeLanguage]}
        subHeadingText={subHeadingText?.[activeLanguage]}
        wrapperClassName={classNames(backgroundColor, {
          'pt-6 768:pt-12': isFirstSectionAfterLandingPage,
        })}
        id={scrollId ? scrollId[activeLanguage] : undefined}
      >
        {(withExtraInfoAboutFishKeeping ||
          section.periodIndicatorHtml?.[activeLanguage]) && (
          <FadeInAnimation className="flex flex-col items-center mb-8 space-y-8">
            <div className="flex flex-col items-center">
              {withExtraInfoAboutFishKeeping && (
                <div className="flex flex-col items-center max-w-lg mb-5 text-center">
                  <Icon
                    icon="fish"
                    className="flex flex-shrink-0 w-8 h-8 text-primary-main"
                  />
                  <p className="text-lg text-gray-900">
                    {
                      translations?.[
                        sortedFishForSale.length > 0
                          ? 'theFollowingFishTypesCanBeKept'
                          : 'noFishTypesCanBeKept'
                      ]?.[activeLanguage]
                    }{' '}
                    {sortedFishForSale.map((fish, index) => (
                      <span
                        key={index}
                        className="text-lg font-semibold text-gray-900"
                      >
                        {fish.name[activeLanguage]}
                        {isLastIndex(index, sortedFishForSale) ? '' : ', '}
                      </span>
                    ))}
                  </p>
                </div>
              )}
              {section.periodIndicatorHtml?.[activeLanguage] && (
                <div className="flex flex-col items-center justify-center text-center">
                  <Icon
                    icon="clock"
                    className="flex-shrink-0 w-8 h-8 text-primary-main"
                  />
                  <p
                    className="text-lg text-gray-900"
                    dangerouslySetInnerHTML={{
                      __html: section.periodIndicatorHtml?.[activeLanguage],
                    }}
                  ></p>
                </div>
              )}
            </div>
          </FadeInAnimation>
        )}
        <PricesSection
          activeLanguage={activeLanguage}
          alerts={pageContext.alerts}
          promotions={pageContext.promotions}
          section={section}
        />
      </SectionBasicWrapper>
      <Separator
        style={bottomSeparator.style}
        metadata={bottomSeparator.metadata}
        location="bottom"
      />
    </>
  );
};

export default PricesSectionWithWrapper;
